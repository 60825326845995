import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Actions as CONSUMPTION_ACTIONS } from '../../store/actions/consumptionActions';
import { Actions as REPORT_ACTIONS } from '../../store/actions/reportActions';
import { AddButton, AnchorLink, ConsumptionReportBtn } from '../../styledComponents/GlobalStyle';
import { PeriodMaker } from '../utils/GlobalFunctions';
import GreenhouseEmissionsAndIntensityRatioRow from './GreenhouseEmissionsAndIntensityRatioRow';
import TotalGreenhouseEmissionsByScopeChart from './TotalGreenhouseEmissionsByScopeChart';
import Select, { components } from 'react-select';
import { Tooltip } from '@mui/material';
import moment from 'moment';
import EmissionsBySourceChart from './EmissionsBySourceChart';

const CustomMultiValue = (props) => {
  const VALUE_LIMIT = 3;
  const { index, getValue } = props;
  const hiddenLength = getValue().length - VALUE_LIMIT;

  return index < VALUE_LIMIT ? (
    <components.MultiValue {...props} />
  ) : index === VALUE_LIMIT ? (
    <div className='option-label'>{`+ ${hiddenLength} selected`}</div>
  ) : null;
};

const CustomOption = (props) => {
  let disabled = false;
  props.options.map((group) => {
    if (group.label === 'Unavailable Organisations') {
      for (const option of group.options) {
        if (option.label === props.label) {
          if (option.isDisabled) {
            disabled = true;
          }
        }
      }
    }
  });
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: disabled === true ? '#D9D9D9' : 'white',
        color: disabled === true ? '#5E5E5E' : 'black',
      }}>
      {' '}
      <components.Option {...props}>
        {' '}
        <input type='checkbox' checked={props.isSelected} disabled={disabled} onChange={() => null} />{' '}
        <label /* style={{ textDecoration: disabled && 'line-through' }} */>{props.label}</label>{' '}
      </components.Option>{' '}
    </div>
  );
};

const GreenhouseEmissionsAndIntensityRatios = ({
  report,
  totalEmissionsByScope,
  consumptionFilter,
  metrics,
  organisationMetrics,
  organisationDetails,
  toggleConsumptionFilter,
  withMarketFactors,
  industries,
  orgOptions,
  currentOrganisation,
  getConsumption,
  saveIncludedOrgs,
  adminOrgs,
  includedOrganisations,
  orgsInReport,
  branding,
  hasAccess,
}) => {
  const [organisations, setOrganisations] = useState([]);
  const { primaryColor, categoryBackground } = branding.colors || {};

  useEffect(() => {
    const selectedOrgs = localStorage.getItem('selectedOrgs');
    if (selectedOrgs) {
      setOrganisations(JSON.parse(selectedOrgs));
    }
    if (includedOrganisations?.length > 0) {
      toggleConsumptionFilter('m1');
      toggleConsumptionFilter('m2');
      toggleConsumptionFilter('m3');
    }
  }, []);

  if (!report || !organisationDetails || !organisationMetrics || !totalEmissionsByScope) {
    return null;
  }

  const year = report.year;

  const hasComparative = totalEmissionsByScope && totalEmissionsByScope[year - 1];

  const referenceYear = +report.endDate.split('-')[0];
  const lastPeriod = hasComparative ? PeriodMaker(report.startDate, report.endDate, referenceYear - 1) : null;
  const currentPeriod = PeriodMaker(report.startDate, report.endDate, referenceYear);

  const options = ['m1', 'm2', 'm3', 'revenue', 'co2e'];

  const filterClasses = consumptionFilter.map((item) => `hide-${item}`);

  return (
    <div>
      <style>
        {`.hide-comparative .toggle-comparative { display: none }`}
        {`.hide-market .toggle-market { display: none }`}
        {`.hide-location .toggle-location { display: none }`}
        {options && options.map((key) => `.hide-${key} .toggle-${key} { display: none }`)}
      </style>
      <div className='hierarchy-select-div'>
        {orgOptions?.length > 0 && hasAccess && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ minWidth: '230px' }}>
              <Select
                name={'organisations'}
                options={orgOptions}
                onChange={(selected) => {
                  if (selected == null) {
                    setOrganisations([]);
                    localStorage.removeItem('selectedOrgs');
                  } else {
                    localStorage.setItem('selectedOrgs', JSON.stringify(selected));
                    setOrganisations(selected);
                  }
                }}
                style={{ float: 'left', marginLeft: 0 }}
                styles={{
                  option: (base, state) => ({
                    ...base,
                    height: '100%',
                    color: state.isSelected && 'black',
                    backgroundColor: state.isSelected ? 'white' : state.isFocused && 'white',
                  }),
                  groupHeading: (provided) => ({
                    ...provided,
                    color: '#6b7280',
                    fontSize: '11px',
                    textAlign: 'center',
                  }),
                }}
                isClearable={true}
                isMulti={true}
                placeholder={'Child Organisations'}
                menuPlacement='auto'
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                value={organisations}
                components={{
                  MultiValue: (props) => <CustomMultiValue {...props} />,
                  Option: CustomOption,
                }}
              />
            </div>
            <AddButton
              style={{
                float: 'left',
                marginLeft: '5px',
                marginRight: '5px',
                maxHeight: '36px',
              }}
              onClick={() => {
                if (organisations.length > 0) {
                  //call consumptionHelper
                  getConsumption(false, false, [...organisations?.map((org) => org.value)]);
                  saveIncludedOrgs([...organisations?.map((org) => org.value)]);
                } else {
                  getConsumption(false, false);
                  saveIncludedOrgs([]);
                }
              }}>
              Generate
            </AddButton>

            <Tooltip
              title={
                <span style={{ fontSize: 12 }}>
                  {
                    'Organisations listed as unavailable were not owned by the parent organisation during the reporting period.\nYou can update the dates of ownership on the organisation page.'
                  }
                </span>
              }
              arrow
              placement='right'
              PopperProps={{ sx: { '.MuiTooltip-tooltip': { bgcolor: primaryColor } } }}>
              <i
                className='fa fa-question-circle'
                style={{ fontSize: 18, color: primaryColor, marginLeft: 2, marginRight: 5 }}
              />
            </Tooltip>
          </div>
        )}
        {hasComparative && (
          <div
            style={{
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              marginRight: 0,
            }}>
            <AnchorLink
              className={`btn ${consumptionFilter.includes('comparative') ? 'btn-default' : 'btn-success'}`}
              style={{ float: 'right', alignContent: 'center', maxHeight: '36px' }}
              onClick={() => toggleConsumptionFilter('comparative')}>
              <i className='fa-regular fa-chart-bar' /> &nbsp; Check Comparative
            </AnchorLink>
          </div>
        )}
      </div>

      <div className='clearfix'></div>
      <div className='clearfix'>
        <div id='showReportMetrics-btns' style={{ float: 'right', marginTop: '10px' }}>
          <span>Show: </span>

          <ConsumptionReportBtn
            className={`btn ${consumptionFilter.includes('co2e') ? 'btn-default' : 'btn-success'}`}
            onClick={() => toggleConsumptionFilter('co2e')}
            style={{ marginRight: '5px' }}>
            Co2e
          </ConsumptionReportBtn>

          <ConsumptionReportBtn
            className={`btn ${consumptionFilter.includes('revenue') ? 'btn-default' : 'btn-success'}`}
            onClick={() => toggleConsumptionFilter('revenue')}
            style={{ marginRight: '5px' }}>
            per million Revenue
          </ConsumptionReportBtn>

          {organisationDetails &&
            ['intensityRatioMetric1ID', 'intensityRatioMetric2ID', 'intensityRatioMetric3ID'].map((ir, index) => {
              const orgIndustry = _.find(industries, { id: organisationDetails?.industry });
              const metricID = orgIndustry?.[ir];
              const metric = metrics && metrics.find((metric) => metric.id === metricID);
              const metricName =
                metric && (metric.name === 'Unit' ? orgIndustry.descriptionOfNonStandardRatio : metric.name);

              return (
                metric &&
                metricID &&
                metricID !== '230393' && (
                  <ConsumptionReportBtn
                    key={index}
                    className={`btn ${consumptionFilter.includes(`m${index + 1}`) ? 'btn-default' : 'btn-success'}`}
                    style={{ marginRight: '5px' }}
                    onClick={() => toggleConsumptionFilter(`m${index + 1}`)}>
                    per {metricName}
                  </ConsumptionReportBtn>
                )
              );
            })}
        </div>
      </div>
      {withMarketFactors && (
        <div id='showLocationOrMarketBased-btns' style={{ float: 'right', marginTop: '10px' }}>
          <span>Show: </span>
          <AnchorLink
            onClick={() => {
              toggleConsumptionFilter('market');
              toggleConsumptionFilter('location');
            }}
            style={{ marginLeft: '1vh' }}
            id='marketBased-btn'
            className={`btn ${consumptionFilter.includes('market') ? 'btn-default' : 'btn-success'}`}>
            Market Based
          </AnchorLink>

          <AnchorLink
            onClick={() => {
              toggleConsumptionFilter('market');
              toggleConsumptionFilter('location');
            }}
            style={{ marginLeft: '1vh' }}
            className={`btn ${consumptionFilter.includes('market') ? 'btn-success' : 'btn-default'}`}
            id='locationBased-btn'>
            Location Based
          </AnchorLink>
        </div>
      )}
      <h3>Total Reported Greenhouse Gas Emissions and Intensity Ratios</h3>
      <br />
      {includedOrganisations?.length === 0 && (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              fontWeight: 600,
              fontSize: '15px',
              borderBottom: '1px solid #ddd',
            }}>
            {'Organisation: ' + adminOrgs?.find((org) => org.id === currentOrganisation)?.name}
          </div>
          <table
            id={`GHGAndIntensityRatios-table`}
            className={`table table-hover general-table pie ${filterClasses.join(' ')}`}
            style={{ textAlign: 'right' }}>
            <thead>
              <tr>
                <th>Scope</th>
                {hasComparative && (
                  <th className='toggle-comparative toggle-co2e' style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                    Total <span style={{ whiteSpace: 'nowrap' }}>(tCO2e)</span>
                    <br />({lastPeriod})
                  </th>
                )}
                <th className='toggle-co2e' style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                  Total <span style={{ whiteSpace: 'nowrap' }}>(tCO2e)</span> <br />({currentPeriod})
                </th>
                {hasComparative && (
                  <th className='toggle-comparative toggle-revenue' style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                    tCO2e / <span style={{ whiteSpace: 'nowrap' }}>Revenue</span> <br />({lastPeriod})
                  </th>
                )}
                <th className='toggle-revenue' style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                  tCO2e / <span style={{ whiteSpace: 'nowrap' }}>Revenue</span> <br />({currentPeriod})
                </th>
                {organisationDetails &&
                  ['intensityRatioMetric1ID', 'intensityRatioMetric2ID', 'intensityRatioMetric3ID'].map((ir, index) => {
                    const orgIndustry = _.find(industries, { id: organisationDetails?.industry });
                    const metricID = orgIndustry?.[ir];
                    const metric = metrics && metrics.find((metric) => metric.id == metricID);
                    const metricName =
                      metric && (metric.name == 'Unit' ? orgIndustry.descriptionOfNonStandardRatio : metric.name);

                    return (
                      metric &&
                      metricID &&
                      metricID !== '230393' && [
                        hasComparative && (
                          <th
                            key={crypto.randomUUID()}
                            className={`toggle-m${index + 1} toggle-comparative`}
                            style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                            tCO2e / <span style={{ whiteSpace: 'nowrap' }}>{metricName}</span> <br />({lastPeriod})
                          </th>
                        ),
                        <th
                          key={crypto.randomUUID()}
                          className={`toggle-m${index + 1}`}
                          style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                          tCO2e / <span style={{ whiteSpace: 'nowrap' }}>{metricName}</span> <br />({currentPeriod})
                        </th>,
                      ]
                    );
                  })}
              </tr>
            </thead>
            <tbody>
              <GreenhouseEmissionsAndIntensityRatioRow org={currentOrganisation} scope='1' key={1} />
              <GreenhouseEmissionsAndIntensityRatioRow org={currentOrganisation} scope='2' key={2} />
              <GreenhouseEmissionsAndIntensityRatioRow org={currentOrganisation} scope='3' key={3} />
              <GreenhouseEmissionsAndIntensityRatioRow org={currentOrganisation} key={'All Scopes'} />
            </tbody>
          </table>
        </>
      )}

      {/* {totalEmissionsByScope?.[year] &&
        Object?.keys(totalEmissionsByScope[year])?.length > 1 &&
        Object?.keys(totalEmissionsByScope[year])
          ?.filter((org) => org !== currentOrganisation)
          ?.map((org, index) => {
            organisationDetails = adminOrgs?.find((organisation) => organisation.id === org);
            return (
              <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    fontWeight: 600,
                    fontSize: '15px',
                    borderBottom: '1px solid #ddd',
                  }}>
                  {'Organisation: ' + adminOrgs?.find((organisation) => organisation.id === org)?.name}
                </div>
                <table
                  id={`GHGAndIntensityRatios-table-${org}`}
                  className={`table table-hover general-table pie ${filterClasses.join(' ')}`}
                  style={{ textAlign: 'right' }}
                  key={`${org}`}>
                  <thead>
                    <tr>
                      <th>Scope</th>
                      <th className='toggle-co2e' style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                        Total <span style={{ whiteSpace: 'nowrap' }}>(tCO2e)</span> <br />({currentPeriod})
                      </th>
                      {hasComparative && (
                        <th
                          className='toggle-comparative toggle-co2e'
                          style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                          Total <span style={{ whiteSpace: 'nowrap' }}>(tCO2e)</span>
                          <br />({lastPeriod})
                        </th>
                      )}
                      <th className='toggle-revenue' style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                        tCO2e / <span style={{ whiteSpace: 'nowrap' }}>Revenue</span> <br />({currentPeriod})
                      </th>
                      {hasComparative && (
                        <th
                          className='toggle-comparative toggle-revenue'
                          style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                          tCO2e / <span style={{ whiteSpace: 'nowrap' }}>Revenue</span> <br />({lastPeriod})
                        </th>
                      )}
                      {organisationDetails &&
                        ['intensityRatioMetric1ID', 'intensityRatioMetric2ID', 'intensityRatioMetric3ID'].map(
                          (ir, index) => {
                            const orgIndustry = _.find(industries, { id: organisationDetails?.industry });
                            const metricID = orgIndustry?.[ir];
                            const metric = metrics && metrics.find((metric) => metric.id == metricID);
                            const metricName =
                              metric && (metric.name == 'Unit' ? orgIndustry.descriptionOfNonStandardRatio : metric.name);

                            return (
                              metric &&
                              metricID &&
                              metricID !== '230393' && [
                                <th
                                  key={crypto.randomUUID()}
                                  className={`toggle-m${index + 1}`}
                                  style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                                  tCO2e / <span style={{ whiteSpace: 'nowrap' }}>{metricName}</span> <br />({currentPeriod})
                                </th>,
                                hasComparative && (
                                  <th
                                    key={crypto.randomUUID()}
                                    className={`toggle-m${index + 1} toggle-comparative`}
                                    style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                                    tCO2e / <span style={{ whiteSpace: 'nowrap' }}>{metricName}</span> <br />({lastPeriod})
                                  </th>
                                ),
                              ]
                            );
                          }
                        )}
                    </tr>
                  </thead>
                  <tbody>
                    <GreenhouseEmissionsAndIntensityRatioRow org={org} scope='1' key={1} />
                    <GreenhouseEmissionsAndIntensityRatioRow org={org} scope='2' key={2} />
                    <GreenhouseEmissionsAndIntensityRatioRow org={org} scope='3' key={3} />
                    <GreenhouseEmissionsAndIntensityRatioRow org={org} key={'All Scopes'} />
                  </tbody>
                </table>
              </>
            );
          })} */}

      {totalEmissionsByScope[year] &&
        includedOrganisations?.length > 0 /* Object?.keys(totalEmissionsByScope[year])?.length > 1 */ && (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                fontWeight: 600,
                fontSize: '15px',
                borderBottom: '1px solid #ddd',
              }}>
              {'Organisations: ' + orgsInReport}
            </div>
            <table
              id={`GHGAndIntensityRatios-table-group`}
              className={`table table-hover general-table pie ${filterClasses.join(' ')}`}
              style={{ textAlign: 'right' }}>
              <thead>
                <tr>
                  <th>Scope</th>
                  {hasComparative && (
                    <th className='toggle-comparative toggle-co2e' style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                      Total <span style={{ whiteSpace: 'nowrap' }}>(tCO2e)</span>
                      <br />({lastPeriod})
                    </th>
                  )}
                  <th className='toggle-co2e' style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                    Total <span style={{ whiteSpace: 'nowrap' }}>(tCO2e)</span> <br />({currentPeriod})
                  </th>
                  {hasComparative && (
                    <th
                      className='toggle-comparative toggle-revenue'
                      style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                      tCO2e / <span style={{ whiteSpace: 'nowrap' }}>Revenue</span> <br />({lastPeriod})
                    </th>
                  )}
                  <th className='toggle-revenue' style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                    tCO2e / <span style={{ whiteSpace: 'nowrap' }}>Revenue</span> <br />({currentPeriod})
                  </th>
                  {/* {organisationDetails &&
                    ['intensityRatioMetric1ID', 'intensityRatioMetric2ID', 'intensityRatioMetric3ID'].map((ir, index) => {
                      const orgIndustry = _.find(industries, { id: organisationDetails?.industry });
                      const metricID = orgIndustry?.[ir];
                      const metric = metrics && metrics.find((metric) => metric.id == metricID);
                      const metricName =
                        metric && (metric.name == 'Unit' ? orgIndustry.descriptionOfNonStandardRatio : metric.name);

                      return (
                        metric &&
                        metricID &&
                        metricID !== '230393' && [
                          console.log(metricID),
                          <th
                            key={crypto.randomUUID()}
                            className={`toggle-m${index + 1}`}
                            style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                            tCO2e / <span style={{ whiteSpace: 'nowrap' }}>{metricName}</span> <br />({currentPeriod})
                          </th>,
                          hasComparative && (
                            <th
                              key={crypto.randomUUID()}
                              className={`toggle-m${index + 1} toggle-comparative`}
                              style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                              tCO2e / <span style={{ whiteSpace: 'nowrap' }}>{metricName}</span> <br />({lastPeriod})
                            </th>
                          ),
                        ]
                      );
                    })} */}
                </tr>
              </thead>
              <tbody>
                <GreenhouseEmissionsAndIntensityRatioRow org={'all'} scope='1' key={1} />
                <GreenhouseEmissionsAndIntensityRatioRow org={'all'} scope='2' key={2} />
                <GreenhouseEmissionsAndIntensityRatioRow org={'all'} scope='3' key={3} />
                <GreenhouseEmissionsAndIntensityRatioRow org={'all'} key={'All Scopes'} />
              </tbody>
            </table>
          </>
        )}

      <p>tCO2e – tonnes of carbon dioxide equivalent emissions</p>
      <br />
      <br />
      <div className='flex-dir' style={{ width: '100%', justifyContent: 'space-evenly' }}>
        <div className='bar-chart'>
          <TotalGreenhouseEmissionsByScopeChart />
        </div>
        <div className='donut-chart'>
          <EmissionsBySourceChart />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { totalEmissionsByScope, withMarketFactors, years, filter } = state.consumption || {};
  const adminOrgs = state.admin?.organisations;
  const { cyfAdmin, whiteLabelAdmin } = state.profile?.details || {};
  const adminUser = cyfAdmin || whiteLabelAdmin;
  const { organisation, report } = state;
  const organisationList = organisation?.list;
  const organisations = adminUser ? adminOrgs : organisationList;
  const organisationMetrics = organisation?.metrics;
  const currentOrganisation = state.currentOrganisation;
  const isParent =
    organisation?.details?.parentOrganisation === true ||
    organisation?.details?.parentOrganisation === currentOrganisation ||
    organisation?.details?.parentOrganisation === null;
  const includedOrganisations = state.report?.includedOrgs;
  //Returns the Ids of the organisation Children
  const orgChildren = organisation?.hierarchy?.map((org) => {
    return org.org;
  });
  //Returns the organisation object of the children which the user has access to
  const accessibleChildren = adminUser
    ? _.uniq(
        orgChildren
          ?.map((org) => {
            const found = adminOrgs?.find((organisation) => organisation?.id === org);
            if (found) {
              return found;
            }
          })
          ?.filter(Boolean)
      )
    : _.uniq(
        orgChildren
          ?.map((org) => {
            const found = organisation?.list?.find((organisation) => organisation?.id === org);
            if (found) {
              return found;
            }
          })
          ?.filter(Boolean)
      );

  const currentOrgName = organisations?.find((org) => org.id === currentOrganisation)?.name;
  const orgsInReport =
    includedOrganisations?.length > 2
      ? currentOrgName + ' and ' + includedOrganisations?.length + ' others'
      : currentOrgName +
        ', ' +
        includedOrganisations?.map((organisation) => organisations?.find((org) => org.id === organisation)?.name).join(', ');

  let orgOptions = accessibleChildren?.map((org) => {
    // Check if organisation is owned during this period
    const ownership = organisation.hierarchy?.find(
      (o) =>
        o.org === org?.id &&
        (moment(report.details.startDate).isBetween(o.startDate, o.endDate, null, '[]') ||
          moment(report.details.endDate).isBetween(o.startDate, o.endDate, null, '[]') ||
          (moment(o.startDate).isBetween(report.details.startDate, report.details.endDate, null, '[]') &&
            moment(o.endDate).isBetween(report.details.startDate, report.details.endDate, null, '[]')))
    );
    if (!ownership) {
      return { value: org?.id, label: org?.name, isDisabled: true };
    } else {
      return { value: org?.id, label: org?.name };
    }
  });

  const enabledOptions = orgOptions.filter((option) => !option.isDisabled);
  const disabledOptions = orgOptions.filter((option) => option.isDisabled);

  orgOptions = [
    {
      label: 'Available Organisations',
      options: enabledOptions,
    },
    {
      label: 'Unavailable Organisations',
      options: disabledOptions,
    },
  ];

  const features = state?.features;
  const availableFeatures = state.organisation?.details?.features;
  const feature = features?.find((feature) => feature.featureKey === 'ORGANISATION_HIERARCHY');
  const hasAccess = availableFeatures?.includes('ORGANISATION_HIERARCHY') || feature?.enabledByDefault;

  return {
    withMarketFactors,
    report: report?.details,
    totalEmissionsByScope: totalEmissionsByScope,
    years: years || [],
    metrics: state.metric?.list,
    organisationDetails: organisation?.details,
    organisationMetrics: organisationMetrics,
    consumptionFilter: filter || [],
    industries: organisation?.industries,
    orgOptions: orgOptions, //accessibleChildren && accessibleChildren?.map((org) => ({ value: org?.id, label: org?.name })),
    currentOrganisation,
    adminOrgs: adminUser ? adminOrgs : organisation?.list,
    includedOrganisations,
    orgsInReport,
    branding: state.branding,
    hasAccess: hasAccess || false,
  };
};

const mapDispatchToProps = (dispatch) => ({
  toggleConsumptionFilter: (filter) => dispatch(CONSUMPTION_ACTIONS.toggleConsumptionFilter(filter)),
  getConsumption: (monthly, stepFunctionSucceeded, childOrgs) =>
    dispatch(CONSUMPTION_ACTIONS.getConsumption(monthly, stepFunctionSucceeded, childOrgs)),
  saveIncludedOrgs: (orgs) => dispatch(REPORT_ACTIONS.saveIncludedOrgs(orgs)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GreenhouseEmissionsAndIntensityRatios);
