import React from 'react';
import { connect } from 'react-redux';

import { format } from 'date-fns';
import { monthsShort } from 'moment';

const ElectricityRenewable = ({ years, electricityTotal, months, getYears, yearTitles }) => {
  if (!electricityTotal) return null;

  if (months.length > 0) {
    return (
      <div style={{ overflow: 'auto' }}>
        <h3>Electricity by Source</h3>
        <table
          id='electricty-by-renewables-table'
          className={`table table-hover general-table`}
          style={{ textAlign: 'right' }}>
          <thead>
            <tr>
              <th width='20%'> Electricity Consumption </th>

              {['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].map((month) => {
                return getYears.map((year) => {
                  return (
                    <th className={`toggle-year-${year} toggle-month-${month}`} width='15%' style={{ textAlign: 'right' }}>
                      {format(new Date(year, month - 1), 'MMM yy')}
                    </th>
                  );
                });
              })}
            </tr>
          </thead>

          <tbody>
            <tr>
              <td style={{ textAlign: 'left' }}>Purchased Renewable</td>
              {['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].map((month) => {
                return getYears.map((year) => {
                  return (
                    <td className={`toggle-year-${year} toggle-month-${month}`} width='15%' style={{ textAlign: 'right' }}>
                      {(electricityTotal[`${year}-${month}`] &&
                        electricityTotal[`${year}-${month}`].renewable &&
                        `${electricityTotal[`${year}-${month}`].renewable.toFixed(3)} kWh`) ||
                        `0 `}
                    </td>
                  );
                });
              })}
            </tr>
            <tr>
              <td style={{ textAlign: 'left' }}>Self-Generated Renewable</td>
              {['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].map((month) => {
                return getYears.map((year) => {
                  return (
                    <td className={`toggle-year-${year} toggle-month-${month}`} width='15%' style={{ textAlign: 'right' }}>
                      {(electricityTotal[`${year}-${month}`] &&
                        electricityTotal[`${year}-${month}`].generated &&
                        `${electricityTotal[`${year}-${month}`].generated.toFixed(3)} kWh`) ||
                        `0 `}
                    </td>
                  );
                });
              })}
            </tr>

            <tr>
              <td style={{ textAlign: 'left' }}>Total Renewable</td>
              {['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].map((month) => {
                return getYears.map((year) => {
                  const generated =
                    (electricityTotal[`${year}-${month}`] && electricityTotal[`${year}-${month}`].generated) || 0;
                  const renewable =
                    (electricityTotal[`${year}-${month}`] && electricityTotal[`${year}-${month}`].renewable) || 0;
                  const total = generated + renewable || 0;
                  return (
                    <td className={`toggle-year-${year} toggle-month-${month}`} width='15%' style={{ textAlign: 'right' }}>
                      {`${total.toFixed(3)} kWh` || `0 kWh`}
                    </td>
                  );
                });
              })}
            </tr>
            <tr>
              <td style={{ textAlign: 'left' }}>Total Electricity</td>
              {['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].map((month) => {
                return getYears.map((year) => {
                  return (
                    <td className={`toggle-year-${year} toggle-month-${month}`} width='15%' style={{ textAlign: 'right' }}>
                      {(electricityTotal[`${year}-${month}`] &&
                        electricityTotal[`${year}-${month}`].total &&
                        `${electricityTotal[`${year}-${month}`].total.toFixed(3)} kWh`) ||
                        `0 kWh`}
                    </td>
                  );
                });
              })}
            </tr>

            <tr>
              <td style={{ textAlign: 'left' }}>% Purchased Renewable</td>
              {['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].map((month) => {
                return getYears.map((year) => {
                  const renewable =
                    (electricityTotal[`${year}-${month}`] && electricityTotal[`${year}-${month}`].renewable) || 0;
                  const total = (electricityTotal[`${year}-${month}`] && electricityTotal[`${year}-${month}`].total) || 0;

                  const percentage = total ? ((renewable / total) * 100).toFixed(3) : 'N/A';
                  return (
                    <td
                      className={`toggle-year-${year} toggle-month-${monthsShort}`}
                      width='15%'
                      style={{ textAlign: 'right' }}>
                      {percentage}
                      {!isNaN(percentage) && `%`}
                    </td>
                  );
                });
              })}
            </tr>
            <tr>
              <td style={{ textAlign: 'left' }}>% Self-Generated Renewable</td>
              {['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].map((month) => {
                return getYears.map((year) => {
                  const renewable =
                    (electricityTotal[`${year}-${month}`] && electricityTotal[`${year}-${month}`].generated) || 0;
                  const total = (electricityTotal[`${year}-${month}`] && electricityTotal[`${year}-${month}`].total) || 0;

                  const percentage = total ? ((renewable / total) * 100).toFixed(3) : 'N/A';
                  return (
                    <td
                      className={`toggle-year-${year} toggle-month-${monthsShort}`}
                      width='15%'
                      style={{ textAlign: 'right' }}>
                      {percentage}
                      {!isNaN(percentage) && `%`}
                    </td>
                  );
                });
              })}
            </tr>
            <tr>
              <td style={{ textAlign: 'left' }}>% Total Renewable</td>
              {['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].map((month) => {
                return getYears.map((year) => {
                  const renewable =
                    (electricityTotal[`${year}-${month}`] && electricityTotal[`${year}-${month}`].renewable) || 0;
                  const generated =
                    (electricityTotal[`${year}-${month}`] && electricityTotal[`${year}-${month}`].generated) || 0;
                  const total = (electricityTotal[`${year}-${month}`] && electricityTotal[`${year}-${month}`].total) || 0;

                  const percentage = total ? (((generated + renewable) / total) * 100).toFixed(3) : 'N/A';
                  return (
                    <td
                      className={`toggle-year-${year} toggle-month-${monthsShort}`}
                      width='15%'
                      style={{ textAlign: 'right' }}>
                      {percentage}
                      {!isNaN(percentage) && `%`}
                    </td>
                  );
                });
              })}
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div>
      <h3>Electricity by Source</h3>
      <table className={`table table-hover general-table`} style={{ textAlign: 'right', marginBottom: '50px' }}>
        <thead>
          <tr>
            <th width='20%'>Electricity Consumption</th>

            {years.map((year) => (
              <th key={crypto.randomUUID()} className={`toggle-year-${year}`} width='15%' style={{ textAlign: 'right' }}>
                {yearTitles[year]}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          <tr className='electricity-renewable-row'>
            <td style={{ textAlign: 'left' }}>Purchased 100% Renewable</td>
            {years.map((year) => (
              <td key={crypto.randomUUID()} className={`toggle-year-${year}`} width='15%' style={{ textAlign: 'right' }}>
                {`${
                  parseFloat(electricityTotal?.[year]?.renewable || 0).toLocaleString(navigator.language, {
                    minimumFractionDigits: 3,
                  }) || '0.000'
                } kWh`}
              </td>
            ))}
          </tr>
          <tr className='electricity--generated-renewable-row'>
            <td style={{ textAlign: 'left' }}>Self-Generated 100% Renewable</td>
            {years.map((year) => (
              <td key={crypto.randomUUID()} className={`toggle-year-${year}`} width='15%' style={{ textAlign: 'right' }}>
                {`${
                  parseFloat(electricityTotal?.[year]?.generated || 0).toLocaleString(navigator.language, {
                    minimumFractionDigits: 3,
                  }) || '0.000'
                } kWh`}
              </td>
            ))}
          </tr>
          <tr className='electricity-total-renewable-row'>
            <td style={{ textAlign: 'left' }}>Total 100% Renewable</td>
            {years.map((year) => {
              const renewable = electricityTotal?.[year]?.renewable || 0;
              const generated = electricityTotal?.[year]?.generated || 0;
              const total = renewable + generated || 0;
              return (
                <td key={crypto.randomUUID()} className={`toggle-year-${year}`} width='15%' style={{ textAlign: 'right' }}>
                  {`${
                    parseFloat(total || 0).toLocaleString(navigator.language, {
                      minimumFractionDigits: 3,
                    }) || '0.000'
                  } kWh`}
                </td>
              );
            })}
          </tr>
          <tr className='electricity-total-row'>
            <td style={{ textAlign: 'left' }}>Total Electricity</td>
            {years.map((year) => (
              <td key={crypto.randomUUID()} className={`toggle-year-${year}`} width='15%' style={{ textAlign: 'right' }}>
                {`${
                  parseFloat(electricityTotal?.[year]?.total || 0).toLocaleString(navigator.language, {
                    minimumFractionDigits: 3,
                  }) || '0.000'
                } kWh`}
              </td>
            ))}
          </tr>

          <tr className='percRenewable-row'>
            <td style={{ textAlign: 'left' }}>% Purchased Renewable</td>
            {years.map((year) => {
              const renewable = electricityTotal?.[year]?.renewable || 0;
              const total = electricityTotal?.[year]?.total || 0;

              const percentage = total ? ((renewable / total) * 100).toFixed(3) : 'N/A';

              return (
                <td key={crypto.randomUUID()} className={`toggle-year-${year}`} width='15%' style={{ textAlign: 'right' }}>
                  {percentage}
                  {!isNaN(percentage) && `%`}
                </td>
              );
            })}
          </tr>

          <tr className='percGeneratedRenewable-row'>
            <td style={{ textAlign: 'left' }}>% Self-Generated Renewable</td>
            {years.map((year) => {
              const generated = electricityTotal?.[year]?.generated || 0;
              const total = electricityTotal?.[year]?.total || 0;

              const percentage = total ? ((generated / total) * 100).toFixed(3) : 'N/A';

              return (
                <td key={crypto.randomUUID()} className={`toggle-year-${year}`} width='15%' style={{ textAlign: 'right' }}>
                  {percentage}
                  {!isNaN(percentage) && `%`}
                </td>
              );
            })}
          </tr>

          <tr className='percTotalRenewable-row'>
            <td style={{ textAlign: 'left' }}>% Total Renewable</td>
            {years.map((year) => {
              const renewable = electricityTotal?.[year]?.renewable || 0;
              const generated = electricityTotal?.[year]?.generated || 0;
              const total = electricityTotal?.[year]?.total || 0;

              const percentage = total ? (((renewable + generated) / total) * 100).toFixed(3) : 'N/A';

              return (
                <td key={crypto.randomUUID()} className={`toggle-year-${year}`} width='15%' style={{ textAlign: 'right' }}>
                  {percentage}
                  {!isNaN(percentage) && `%`}
                </td>
              );
            })}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { getYears } = ownProps;
  const currentOrganisation = state?.currentOrganisation;
  const includedOrgs = [currentOrganisation, ...state?.report?.includedOrgs];
  const { years, electricityRenewable, months } = state.consumption || {};
  const report = state?.report?.details;
  const yearTitles = {};
  years?.forEach((y) => {
    yearTitles[y] = report?.startDate?.split('-')[0] !== report?.endDate?.split('-')[0] ? y - 1 + ' - ' + y : y;
  });

  const electricityTotal = {};
  includedOrgs?.forEach((org) => {
    const elecRenewable = electricityRenewable?.[org];
    //loop over each org and store the corresponding objects together in electricityTotal
    if (!elecRenewable) return;
    Object?.keys(elecRenewable)?.forEach((year) => {
      if (!electricityTotal[year]) {
        electricityTotal[year] = {
          renewable: 0,
          generated: 0,
          total: 0,
        };
      }
      electricityTotal[year].renewable += elecRenewable[year]?.renewable || 0;
      electricityTotal[year].generated += elecRenewable[year]?.generated || 0;
      electricityTotal[year].total += elecRenewable[year]?.total || 0;
    });
  });

  return {
    electricityTotal,
    years: years || [],
    months: months || [],
    getYears: getYears || [],
    yearTitles,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ElectricityRenewable);
