import { addDays, addYears, format } from 'date-fns';
import _ from 'lodash';
import { convertToTitleCase, itemToOptions } from '../utils/GlobalFunctions';

export const ModalPropsAdminPortal = (table, state, optionType, item) => {
  const currentOrganisation =
    state?.organisation?.details || state?.organisation?.list?.find((item) => item?.id === state?.currentOrganisation);
  const thisYear = format(new Date(), 'yyyy');
  const lastYear =
    format(new Date(), 'MM-dd') >=
    `${currentOrganisation?.reportingYearEndMonth}-${currentOrganisation?.reportingYearEndDay}`
      ? +thisYear + +'1'
      : thisYear;
  const reportingYearEndMonth = currentOrganisation?.reportingMonth || currentOrganisation?.reportingYearEndMonth; // property name changes depending on Symph or Dynamo
  const reportingYearEndDay = currentOrganisation?.reportingDay || currentOrganisation?.reportingYearEndDay; // property name changes depending on Symph or Dynamo
  const reportingPeriods = [];

  if (currentOrganisation && reportingYearEndMonth && reportingYearEndDay) {
    for (var i = 2015; i <= lastYear; i++) {
      const endDate = new Date(`${i}-${reportingYearEndMonth}-${reportingYearEndDay}`);
      const startDate = addDays(addYears(endDate, -1), 1);

      reportingPeriods.push({
        label: `${format(startDate, 'do MMM yyyy')} - ${format(endDate, 'do MMM yyyy')}`,
        value: i,
      });
    }
  }
  const { primaryButton, textColor } = state?.branding?.colors || {};
  const { organisations: adminOrgs } = state?.admin || {};
  const consultantAdminOrgs = adminOrgs && adminOrgs?.filter((org) => org.id === org.consultantId);
  const whiteLabelAdminOrgs = adminOrgs && adminOrgs?.filter((org) => org.id === org.whiteLabelId);
  const adminFacilities = state?.admin?.adminPortal?.facilities || [];
  const { list: metricList, scope3Categories, types: usageTypes } = state?.metric || {};
  const checkedEntityData = state?.admin?.checkboxes?.checkedUsage || {};
  const { industries } = state?.organisation || {};
  const { types } = state?.facility || {};
  const {
    tenants,
    tenant,
    savedCategory,
    savedMethod,
    entities,
    alertMessage,
    subentities,
    selectedTrackingCategory,
    method,
  } = state || {};
  const user = state?.profile?.details?.firstName + ' ' + state?.profile?.details?.lastName;
  const cyfAdmin = state?.profile?.details?.cyfAdmin;
  const sources = state?.admin?.adminPortal?.electricitySources || {};
  const addOns = state?.admin?.adminPortal?.addOns || {};
  const usagetypes = Object.keys(usageTypes)?.map((key) => ({ value: key, label: key }));
  const currencies = state?.organisation?.currencies || [];
  const defaultValues = state?.admin?.adminPortal?.defaultValues;
  const usageTypeValues = Object.values(usageTypes)
    .flat()
    .filter((ut) => ut?.type === item?.type);

  const yearOptions = [...new Array(new Date().getFullYear() - 2012)]
    .map((item, index) => ({
      label: (2013 + index).toString(),
      value: (2013 + index).toString(),
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const includedOrganisations = state?.report?.includedOrgs || [];
  const organisationList = state?.organisation?.list || [];
  const { whiteLabelAdmin } = state.profile?.details || {};
  const adminUser = cyfAdmin || whiteLabelAdmin || false;
  const organisations = adminUser ? adminOrgs : organisationList;

  let modalProps;

  switch (table) {
    case 'usageData-bulk_search': {
      const idElement = {
        type: 'text',
        placeholder: 'Search by Id',
        name: 'id',
        label: 'Id',
        value: '',
      };

      const datePickerElements = ['dateStart', 'dateEnd']?.map((dateType) => {
        return {
          component: 'usageSearch_datePicker',
          type: dateType,
        };
      });

      const selectElements = [
        'entity',
        'subentity',
        'seat',
        'vehicle',
        'fuel',
        'production',
        'load',
        'waste',
        'metric',
        'organisation',
        'facility',
      ]?.map((filter) => {
        const filterOptions =
          filter === 'entity'
            ? entities
            : filter === 'subentity'
            ? subentities
            : filter === 'metric'
            ? metricList
            : filter === 'organisation'
            ? adminOrgs
            : filter === 'facility'
            ? adminFacilities
            : usageTypes[filter];

        return {
          component: 'select',
          placeholder: `${filter?.charAt(0).toUpperCase() + filter?.slice(1)} Type`,
          options: _.sortBy(filterOptions?.map(itemToOptions), ['label']),
          name: filter,
          isSearchable: true,
          isClearable: true,
          label: filter?.charAt(0).toUpperCase() + filter?.slice(1),
          value: ' ',
        };
      });
      return (modalProps = {
        elements: [idElement, ...selectElements, ...datePickerElements],
        modalTitle: 'Search More',
        btn: {
          title: 'Search',
          style: { marginTop: 5, backgroundColor: primaryButton },
        },
        tableName: 'usageData',
      });
    }

    case 'usageData-bulk_edit': {
      const selectElements = [
        'entity',
        'subentity',
        'seat',
        'vehicle',
        'fuel',
        'production',
        'load',
        'waste',
        'metric',
        'finallocation',
        'originlocation',
        'price',
        'factorprovider',
        'organisation',
        'facility',
      ]?.map((filter) => {
        const filterOptions =
          filter === 'entity'
            ? entities
            : filter === 'subentity'
            ? subentities
            : filter === 'metric'
            ? metricList
            : filter === 'organisation'
            ? adminOrgs
            : filter === 'facility'
            ? adminFacilities
            : usageTypes[filter];

        return {
          component: 'select',
          placeholder: `${filter?.charAt(0).toUpperCase() + filter?.slice(1)} Type`,
          width: 150,
          options: _.sortBy(filterOptions?.map(itemToOptions), ['label']),
          name: filter,
          label: filter?.charAt(0).toUpperCase() + filter?.slice(1),
          value: ' ',
        };
      });

      return (modalProps = {
        elements: [...selectElements],
        modalTitle: `Please select your desired changes for this Usage Data.`,
        modalSubtitle: 'This action cannot be undone.',
        modalTableTitle: `${alertMessage?.severity === 'error' ? 'Your Incorrect Submissions' : 'Your Previous Records'}`,
        btn: {
          title: 'Next',
          style: { marginBottom: 10, backgroundColor: primaryButton, textAlign: 'center' },
          type: 'submit',
        },
        hideBtns: alertMessage?.severity === 'error' ? true : false,
        tableName: 'usageData',
      });
    }

    case 'usageData_entity-bulk_delete': {
      return (modalProps = {
        modalTitle: `Are you sure you want to delete ${checkedEntityData?.length} ${
          checkedEntityData?.length > 1 ? 'entries' : 'entry'
        } of ${checkedEntityData[0]?.entityName} Usage Data?`,
        modalSubtitle: 'This action cannot be undone.',
        btn: {
          title: `Delete ${checkedEntityData?.length} ${checkedEntityData?.length > 1 ? 'entries' : 'entry'} of ${
            checkedEntityData[0]?.entityName
          } usage data`,
          style: { marginTop: 20 },
          type: 'button',
        },
        item: checkedEntityData,
        tableName: 'usageData',
      });
    }

    case 'usageData-bulk_delete': {
      return (modalProps = {
        modalTitle: `Are you sure you want to delete this Usage Data?`,
        modalSubtitle: 'This action cannot be undone.',
        btn: {
          title: `Delete Usage Data`,
          style: { marginTop: 20 },
          type: 'submit',
        },
        tableName: 'usageData',
      });
    }

    case 'usageData-delete': {
      return (modalProps = {
        modalTitle: 'Are you sure you want to delete this Usage Data',
        modalSubtitle: 'This action cannot be undone.',
        btn: {
          title: 'Delete Usage Data',
          style: { marginTop: 20 },
          type: 'submit',
        },
        tableName: 'usageData',
      });
    }

    case 'reports-table-add': {
      return (modalProps = {
        elements: [
          {
            type: 'text',
            placeholder: 'Name',
            name: 'name',
            label: 'Name',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Organisation',
            options: _.sortBy(adminOrgs?.map(itemToOptions), ['label']),
            isSearchable: true,
            name: 'organisationId',
            label: 'Organisation',
            value: '',
          },
          {
            component: 'datePicker',
            placeholder: 'Start Date',
            name: 'startDate',
            label: 'Start Date',
            value: '',
            styles: { width: 260 },
          },
          {
            component: 'datePicker',
            placeholder: 'End Date',
            name: 'endDate',
            label: 'End Date',
            value: '',
            styles: { width: 260 },
          },
        ],
        modalTitle: 'Add Report',
        btn: {
          title: 'Add Report',
          style: { marginTop: 20, backgroundColor: primaryButton, border: 'none', color: textColor },
        },
        tableName: 'reports',
      });
    }

    case 'reports-table-edit': {
      return (modalProps = {
        elements: [
          {
            type: 'text',
            placeholder: 'Name',
            name: 'name',
            label: 'Name',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Organisation',
            options: _.sortBy(adminOrgs?.map(itemToOptions), ['label']),
            isSearchable: true,
            name: 'organisationId',
            label: 'Organisation',
            value: '',
          },
          {
            component: 'datePicker',
            placeholder: 'Start Date',
            name: 'startDate',
            label: 'Start Date',
            value: '',
            styles: { width: 260 },
          },
          {
            component: 'datePicker',
            placeholder: 'End Date',
            name: 'endDate',
            label: 'End Date',
            //disabled: true,
            value: '',
            styles: { width: 260 },
          },
          {
            type: 'text',
            placeholder: 'Year',
            name: 'year',
            label: 'Year',
            //disabled: true,
            style: { marginTop: 0 },
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Status',
            options: [
              { label: 'New', value: 'New' },
              { label: 'Deleted', value: 'Deleted' },
              { label: 'Completed', value: 'Completed' },
            ],
            isSearchable: true,
            name: 'status',
            label: 'Status',
            value: '',
          },
        ],
        modalTitle: 'Edit Report',
        btn: {
          title: 'Edit Report',
          style: { marginTop: 20, backgroundColor: primaryButton, border: 'none', color: textColor },
        },
        tableName: 'reports',
      });
    }

    case 'reports-table-delete': {
      return (modalProps = {
        modalTitle: 'Are you sure you want to delete this report?',
        //modalSubtitle: 'This action cannot be undone.',
        btn: {
          title: 'Delete Report',
          style: { marginTop: 20 },
          type: 'submit',
        },
        tableName: 'reports',
      });
    }

    case 'reports-delete-not-allowed': {
      return (modalProps = {
        modalTitle: 'Please, contact sales to delete this report.',
        modalSubtitle:
          'Report was generated more than 30 days ago and will likely require a refund. Please consult sales before deleting report.',
      });
    }

    case 'reports-table-cache-delete': {
      return (modalProps = {
        modalTitle: 'Are you sure you want to delete the cache data for this report?',
        btn: {
          title: 'Delete Cache',
          style: { marginTop: 20 },
          type: 'submit',
        },
      });
    }

    case 'conversionFactors-delete': {
      return (modalProps = {
        modalTitle: 'Are you sure you want to delete this Conversion Factor?',
        modalSubtitle: 'This action cannot be undone.',
        btn: {
          title: 'Delete Conversion Factor',
          style: { marginTop: 20 },
          type: 'submit',
        },
        tableName: 'conversionFactors',
      });
    }

    case 'conversionFactors-bulk_edit': {
      const years = [...new Array(new Date().getFullYear() - 2012)].map((_, index) => {
        return {
          label: (2013 + index).toString(),
          value: (2013 + index).toString(),
        };
      });

      const textElements = {
        component: 'text',
        type: 'text',
        placeholder: 'Source',
        name: 'source',
        label: 'Source',
        value: ' ',
        onlyNumbers: false,
      };

      const dateElements = {
        component: 'datePicker',
        placeholder: 'Discontinued Date',
        name: 'discontinuedDate',
        label: 'Discontinued Date',
        value: '',
        styles: { width: 260 },
      };

      const selectElements = [
        'year',
        'subentity',
        'seat',
        'vehicle',
        'fuel',
        'production',
        'load',
        'waste',
        'metric',
        'originlocation',
        'finallocation',
        'price',
        'factorprovider',
        'addOns',
      ]?.map((filter) => {
        const selectOptions =
          filter === 'subentity'
            ? subentities
            : filter === 'metric'
            ? metricList
            : filter === 'year'
            ? years
            : filter === 'addOns'
            ? addOns
            : usageTypes[filter];

        return {
          component: 'select',
          placeholder: `${filter?.charAt(0).toUpperCase() + filter?.slice(1)} Type`,
          //year doesnt need itemToOptions. It is already in the correct format
          width: filter === 'year' ? 100 : filter === 'metric' ? 150 : 200,
          options: _.sortBy(filter === 'year' ? selectOptions : selectOptions?.map(itemToOptions), ['label']),
          name: filter,
          isMulti: filter === 'addOns' ? true : false,
          isClearable: true,
          label: convertToTitleCase(filter),
          value: ' ',
        };
      });
      return (modalProps = {
        elements: [...selectElements, textElements, dateElements],
        modalTitle: `Please select your desired changes for these Conversion Factors.`,
        modalSubtitle: 'This action cannot be undone.',
        modalTableTitle: `${alertMessage?.severity === 'error' ? 'Your Incorrect Submissions' : 'Your Previous Records'}`,
        btn: {
          title: 'Next',
          style: { marginBottom: 10, backgroundColor: primaryButton, textAlign: 'center' },
          type: 'submit',
        },
        hideBtns: alertMessage?.severity === 'error' ? true : false,
        tableName: 'conversionFactors',
      });
    }

    case 'conversionFactors-bulk_delete': {
      return (modalProps = {
        modalTitle: `Are you sure you want to delete these Conversion Factors?`,
        modalSubtitle: 'This action cannot be undone.',
        btn: {
          title: `Delete Conversion Factors`,
          style: { marginTop: 20 },
          type: 'submit',
        },
        tableName: 'conversionFactors',
      });
    }

    case 'conversionFactors-table-add': {
      return (modalProps = {
        elements: [
          {
            component: 'select',
            placeholder: 'Year',
            options: yearOptions,
            isSearchable: true,
            isClearable: false,
            name: 'year',
            label: 'Year',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Sub Entity',
            options: _.sortBy(subentities?.map(itemToOptions), ['label']),
            isSearchable: true,
            isClearable: false,
            name: 'subentity',
            label: 'Sub Entity',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Seat Type',
            isClearable: true,
            options: _.sortBy(usageTypes['seat']?.map(itemToOptions), ['label']),
            name: 'seat',
            label: 'Seat Type',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Vehicle Type',
            isClearable: true,
            options: _.sortBy(usageTypes['vehicle']?.map(itemToOptions), ['label']),
            name: 'vehicle',
            label: 'Vehicle Type',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Fuel Type',
            isClearable: true,
            options: _.sortBy(usageTypes['fuel']?.map(itemToOptions), ['label']),
            name: 'fuel',
            label: 'Fuel Type',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Production Type',
            isClearable: true,
            options: _.sortBy(usageTypes['production']?.map(itemToOptions), ['label']),
            name: 'production',
            label: 'Production Type',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Waste Type',
            isClearanle: true,
            options: _.sortBy(usageTypes['waste']?.map(itemToOptions), ['label']),
            name: 'waste',
            label: 'Waste Type',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Load Type',
            isClearable: true,
            options: _.sortBy(usageTypes['load']?.map(itemToOptions), ['label']),
            name: 'load',
            label: 'Load Type',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Price Type',
            isClearable: true,
            options: _.sortBy(usageTypes['price']?.map(itemToOptions), ['label']),
            name: 'price',
            label: 'Price Type',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Origin Location',
            isClearable: true,
            options: _.sortBy(usageTypes['originlocation']?.map(itemToOptions), ['label']),
            name: 'originlocation',
            label: 'Origin Location',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Final Location',
            isClearable: true,
            options: _.sortBy(usageTypes['finallocation']?.map(itemToOptions), ['label']),
            name: 'finallocation',
            label: 'Final Location',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Factor Provider',
            isClearable: true,
            options: _.sortBy(usageTypes['factorprovider']?.map(itemToOptions), ['label']),
            name: 'factorprovider',
            label: 'Factor Provider',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Metric Type',
            isClearable: true,
            options: _.sortBy(metricList?.map(itemToOptions), ['label']),
            name: 'metric',
            label: 'Metric Type',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Scope',
            isClearable: false,
            options: [
              { label: '1', value: '1' },
              { label: '2', value: '2' },
              { label: '3', value: '3' },
            ],
            name: 'scope',
            label: 'Scope',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'CO2E',
            name: 'co2e',
            label: 'CO2E',
            value: '',
            onlyNumbers: true,
          },
          {
            type: 'text',
            placeholder: 'CO2',
            name: 'co2',
            label: 'CO2',
            value: '',
            onlyNumbers: true,
          },
          {
            type: 'text',
            placeholder: 'CH4',
            name: 'ch4',
            label: 'CH4',
            value: '',
            onlyNumbers: true,
          },
          {
            type: 'text',
            placeholder: 'WTT',
            name: 'wtt',
            label: 'WTT',
            value: '',
            onlyNumbers: true,
          },
          {
            type: 'text',
            placeholder: 'N20',
            name: 'n2o',
            label: 'N20',
            value: '',
            onlyNumbers: true,
          },
          {
            type: 'text',
            placeholder: 'Outside of Scopes',
            name: 'outsideOfScopes',
            label: 'Outside of Scopes',
            value: '',
            onlyNumbers: true,
          },
          {
            type: 'text',
            placeholder: 'Source',
            name: 'source',
            label: 'Source',
            value: '',
          },
          {
            component: 'select',
            isMulti: true,
            placeholder: 'Add-Ons',
            name: 'addOns',
            label: 'Included in Add-Ons',
            options: _.sortBy(addOns?.map(itemToOptions), ['label']),
          },
          {
            component: 'datePicker',
            placeholder: 'Discontinued Date',
            name: 'discontinuedDate',
            label: 'Discontinued Date',
            value: '',
          },
          {
            type: 'text',
            placeholder: user,
            name: 'userId',
            label: 'Modified By',
            disabled: true,
            value: user,
          },
        ],
        modalTitle: 'Add Conversion Factor',
        btn: {
          title: 'Add Conversion Factor',
          style: { marginTop: 20, backgroundColor: primaryButton, border: 'none', color: textColor },
        },
        tableName: 'conversionFactors',
      });
    }

    case 'conversionFactors-table-edit': {
      return (modalProps = {
        elements: [
          {
            component: 'select',
            placeholder: 'Year',
            options: yearOptions,
            isSearchable: true,
            isClearable: false,
            name: 'year',
            label: 'Year',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Sub Entity',
            options: _.sortBy(subentities?.map(itemToOptions), ['label']),
            isSearchable: true,
            isClearable: false,
            name: 'subentity',
            label: 'Sub Entity',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Seat Type',
            options: _.sortBy(usageTypes['seat']?.map(itemToOptions), ['label']),
            isClearable: true,
            name: 'seat',
            label: 'Seat Type',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Vehicle Type',
            options: _.sortBy(usageTypes['vehicle']?.map(itemToOptions), ['label']),
            isClearable: true,
            name: 'vehicle',
            label: 'Vehicle Type',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Fuel Type',
            options: _.sortBy(usageTypes['fuel']?.map(itemToOptions), ['label']),
            name: 'fuel',
            label: 'Fuel Type',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Production Type',
            options: _.sortBy(usageTypes['production']?.map(itemToOptions), ['label']),
            isClearable: true,
            name: 'production',
            label: 'Production Type',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Waste Type',
            options: _.sortBy(usageTypes['waste']?.map(itemToOptions), ['label']),
            name: 'waste',
            label: 'Waste Type',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Load Type',
            options: _.sortBy(usageTypes['load']?.map(itemToOptions), ['label']),
            isClearable: true,
            name: 'load',
            label: 'Load Type',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Price Type',
            isClearable: true,
            options: _.sortBy(usageTypes['price']?.map(itemToOptions), ['label']),
            name: 'price',
            label: 'Price Type',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Origin Location',
            isClearable: true,
            options: _.sortBy(usageTypes['originlocation']?.map(itemToOptions), ['label']),
            name: 'originlocation',
            label: 'Origin Location',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Final Location',
            isClearable: true,
            options: _.sortBy(usageTypes['finallocation']?.map(itemToOptions), ['label']),
            name: 'finallocation',
            label: 'Final Location',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Factor Provider',
            isClearable: true,
            options: _.sortBy(usageTypes['factorprovider']?.map(itemToOptions), ['label']),
            name: 'factorprovider',
            label: 'Factor Provider',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Metric Type',
            options: _.sortBy(metricList?.map(itemToOptions), ['label']),
            isClearable: true,
            name: 'metric',
            label: 'Metric Type',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Scope',
            options: [
              { label: '1', value: '1' },
              { label: '2', value: '2' },
              { label: '3', value: '3' },
            ],
            isClearable: false,
            name: 'scope',
            label: 'Scope',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'CO2E',
            name: 'co2e',
            label: 'CO2E',
            value: '',
            onlyNumbers: true,
          },
          {
            type: 'text',
            placeholder: 'CO2',
            name: 'co2',
            label: 'CO2',
            value: '',
            onlyNumbers: true,
          },
          {
            type: 'text',
            placeholder: 'CH4',
            name: 'ch4',
            label: 'CH4',
            value: '',
            onlyNumbers: true,
          },
          {
            type: 'text',
            placeholder: 'WTT',
            name: 'wtt',
            label: 'WTT',
            value: '',
            onlyNumbers: true,
          },
          {
            type: 'text',
            placeholder: 'N20',
            name: 'n2o',
            label: 'N20',
            value: '',
            onlyNumbers: true,
          },
          {
            type: 'text',
            placeholder: 'Outside of Scopes',
            name: 'outsideOfScopes',
            label: 'Outside of Scopes',
            value: '',
            onlyNumbers: true,
          },
          {
            type: 'text',
            placeholder: 'Source',
            name: 'source',
            label: 'Source',
            value: '',
          },
          {
            component: 'select',
            isMulti: true,
            placeholder: 'Add-Ons',
            name: 'addOns',
            label: 'Included in Add-Ons',
            options: _.sortBy(addOns?.map(itemToOptions), ['label']),
          },
          {
            component: 'datePicker',
            placeholder: 'Discontinued Date',
            name: 'discontinuedDate',
            label: 'Discontinued Date',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'User Name',
            name: 'userName',
            label: 'Last Modified By',
            disabled: true,
            value: '',
          },
        ],
        modalTitle: 'Edit Conversion Factor',
        btn: {
          title: 'Edit Conversion Factor',
          style: { marginTop: 20, backgroundColor: primaryButton, border: 'none', color: textColor },
        },
        tableName: 'conversionFactors',
      });
    }

    case 'industries-table-add': {
      return (modalProps = {
        elements: [
          {
            type: 'text',
            placeholder: 'Name',
            name: 'name',
            label: 'Name',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Parent',
            options: _.sortBy(industries?.map(itemToOptions), ['label']),
            name: 'parent',
            label: 'Parent',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'UID',
            name: 'uid',
            label: 'UID',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Description of Non-Standard Ratio',
            name: 'descriptionOfNonStandardRatio',
            label: 'Description of Non-Standard Ratio',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Intensity Ratio Metric 1',
            options: metricList?.map(itemToOptions),
            name: 'intensityRatioMetric1ID',
            label: 'Intensity Ratio Metric 1',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Intensity Ratio Metric 2',
            options: metricList?.map(itemToOptions),
            name: 'intensityRatioMetric2ID',
            label: 'Intensity Ratio Metric 2',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Intensity Ratio Metric 3',
            options: metricList?.map(itemToOptions),
            name: 'intensityRatioMetric3ID',
            label: 'Intensity Ratio Metric 3',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Include',
            options: [
              { label: 'True', value: true },
              { label: 'False', value: false },
            ],
            name: 'include',
            label: 'Name',
            value: '',
          },
        ],
        modalTitle: 'Add an Industry',
        btn: {
          title: 'Add an Industry',
          style: { marginTop: 20, backgroundColor: primaryButton, border: 'none', color: textColor },
        },
        tableName: 'industries',
      });
    }

    case 'industries-table-delete': {
      return (modalProps = {
        modalTitle: 'Are you sure you want to delete this Industry?',
        modalSubtitle: 'This action cannot be undone.',
        btn: {
          title: 'Delete Industry',
          style: { marginTop: 20 },
          type: 'submit',
        },
        tableName: 'industries',
      });
    }

    case 'industries-table-edit': {
      return (modalProps = {
        elements: [
          {
            type: 'text',
            placeholder: 'Name',
            name: 'name',
            label: 'Name',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Parent',
            options: _.sortBy(industries?.map(itemToOptions), ['label']),
            name: 'parent',
            label: 'Parent',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'UID',
            name: 'uid',
            label: 'UID',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Description of Non-Standard Ratio',
            name: 'descriptionOfNonStandardRatio',
            label: 'Description of Non-Standard Ratio',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Intensity Ratio Metric 1',
            options: metricList?.map(itemToOptions),
            name: 'intensityRatioMetric1ID',
            label: 'Intensity Ratio Metric 1',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Intensity Ratio Metric 2',
            options: metricList?.map(itemToOptions),
            name: 'intensityRatioMetric2ID',
            label: 'Intensity Ratio Metric 2',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Intensity Ratio Metric 3',
            options: metricList?.map(itemToOptions),
            name: 'intensityRatioMetric3ID',
            label: 'Intensity Ratio Metric 3',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Include',
            options: [
              { label: 'True', value: true },
              { label: 'False', value: false },
            ],
            name: 'include',
            label: 'Include',
            value: '',
          },
        ],
        modalTitle: 'Edit Industry',
        btn: {
          title: 'Edit Industry',
          style: { marginTop: 20, backgroundColor: primaryButton, border: 'none', color: textColor },
        },
        tableName: 'industries',
      });
    }

    case 'facilityType-table-add': {
      return (modalProps = {
        elements: [
          {
            type: 'text',
            placeholder: 'Name',
            name: 'name',
            label: 'Name',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Description',
            name: 'description',
            label: 'Description',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'CIBSE Category',
            name: 'CIBSE_Category',
            label: 'CIBSE Category',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Electrical Typical CO2 Benchmark',
            name: 'Electrical_typical_CO2_benchmark',
            label: 'Electrical Typical CO2 Benchmark',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Electrical Typical Benchmark',
            name: 'Electricity_typical_benchmark',
            label: 'Electrical Typical Benchmark',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Energy C02e Typical Benchmark',
            name: 'Energy_C02e_typical_benchmark',
            label: 'Energy C02e Typical Benchmark',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Extra Intensity Metric',
            name: 'Extra_Intensity_Metric',
            label: 'Extra Intensity Metric',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Fossil Thermal CO2/m2 Benchmark',
            name: 'Fossil_thermal_CO2_m2_benchmark',
            label: 'Fossil_Thermal CO2/m2 Benchmark',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Fossil Thermal Typical Benchmark',
            name: 'Fossil_thermal_typical_benchmark',
            label: 'Fossil_Thermal Typical Benchmark',
            value: '',
          },
        ],
        modalTitle: 'Add Facility Type',
        btn: {
          title: 'Add Facility Type',
          style: { marginTop: 20, backgroundColor: primaryButton, border: 'none', color: textColor },
        },
        tableName: 'facilityType',
      });
    }

    case 'facilityType-table-edit': {
      return (modalProps = {
        elements: [
          {
            type: 'text',
            placeholder: 'Name',
            name: 'name',
            label: 'Name',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Description',
            name: 'description',
            label: 'Description',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'CIBSE Category',
            name: 'CIBSE_Category',
            label: 'CIBSE Category',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Electrical Typical CO2 Benchmark',
            name: 'Electrical_typical_CO2_benchmark',
            label: 'Electrical Typical CO2 Benchmark',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Electrical Typical Benchmark',
            name: 'Electricity_typical_benchmark',
            label: 'Electrical Typical Benchmark',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Energy C02e Typical Benchmark',
            name: 'Energy_C02e_typical_benchmark',
            label: 'Energy C02e Typical Benchmark',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Extra Intensity Metric',
            name: 'Extra_Intensity_Metric',
            label: 'Extra Intensity Metric',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Fossil Thermal CO2/m2 Benchmark',
            name: 'Fossil_thermal_CO2_m2_benchmark',
            label: 'Fossil Thermal CO2/m2 Benchmark',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Fossil Thermal Typical Benchmark',
            name: 'Fossil_thermal_typical_benchmark',
            label: 'Fossil Thermal Typical Benchmark',
            value: '',
          },
        ],
        modalTitle: 'Edit Facility Type',
        btn: {
          title: 'Edit Facility Type',
          style: { marginTop: 20, backgroundColor: primaryButton, border: 'none', color: textColor },
        },
        tableName: 'facilityType',
      });
    }

    case 'facilityType-table-delete': {
      return (modalProps = {
        modalTitle: 'Are you sure you want to delete this facility type?',
        modalSubtitle: 'This action cannot be undone.',
        btn: {
          id: 'delete-facility-btn',
          title: 'Delete Facility Type',
          style: { marginTop: 20 },
          type: 'submit',
        },
        tableName: 'facilityType',
      });
    }

    case 'electricitySources-table-add': {
      return (modalProps = {
        elements: [
          {
            type: 'text',
            placeholder: 'Source',
            name: 'source',
            label: 'Source',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Renewable',
            options: [
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ],
            name: 'renewable',
            label: 'Renewable',
            value: '',
          },
        ],
        modalTitle: 'Add Electricity Source',
        btn: {
          title: 'Add Electricity Source',
          style: { marginTop: 5, backgroundColor: primaryButton },
        },
        tableName: 'electricitySources',
      });
    }

    case 'electricitySources-table-edit': {
      return (modalProps = {
        elements: [
          {
            type: 'text',
            placeholder: 'Source',
            name: 'source',
            label: 'Source',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Renewable',
            options: [
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ],
            name: 'renewable',
            label: 'Renewable',
            value: '',
          },
        ],
        modalTitle: 'Edit Electricity Source',
        btn: {
          title: 'Edit Electricity Source',
          style: { marginTop: 5, backgroundColor: primaryButton },
        },
        tableName: 'electricitySources',
      });
    }

    case 'electricitySources-table-delete': {
      return (modalProps = {
        modalTitle: 'Are you sure you want to delete this electricity source?',
        //modalSubtitle: 'This action cannot be undone.',
        btn: {
          title: 'Delete Electricity Source',
          style: { marginTop: 20 },
          type: 'submit',
        },
        tableName: 'electricitySources',
      });
    }

    case 'scope3Categories-table-add': {
      return (modalProps = {
        elements: [
          {
            type: 'text',
            placeholder: 'Category Number',
            name: 'categoryNo',
            label: 'Category Number',
            value: '',
            onlyNumbers: true,
          },
          {
            type: 'text',
            placeholder: '',
            name: 'name',
            label: 'Name',
            value: '',
          },
          {
            type: 'textarea',
            placeholder: '',
            name: 'description',
            label: 'Description',
            value: '',
          },
        ],
        modalTitle: 'Add Scope 3 Category',
        btn: {
          title: 'Add Scope 3 Category',
          style: { marginTop: 5, backgroundColor: primaryButton },
        },
        tableName: 'scope3Categories',
      });
    }

    case 'scope3Categories-table-edit': {
      return (modalProps = {
        elements: [
          {
            type: 'text',
            placeholder: 'ID',
            disabled: true,
            name: 'id',
            label: 'ID',
            value: '',
            onlyNumbers: true,
          },
          {
            type: 'text',
            placeholder: 'Category Number',
            name: 'categoryNo',
            label: 'Category Number',
            value: '',
            onlyNumbers: true,
          },
          {
            type: 'text',
            placeholder: 'Name',
            name: 'name',
            label: 'Name',
            value: '',
          },
          {
            type: 'textarea',
            placeholder: 'Description',
            name: 'description',
            label: 'Description',
            value: '',
          },
        ],
        modalTitle: 'Edit Scope 3 Category',
        btn: {
          title: 'Edit Scope 3 Category',
          style: { marginTop: 5, backgroundColor: primaryButton },
        },
        tableName: 'scope3Categories',
      });
    }

    case 'scope3Categories-table-delete': {
      return (modalProps = {
        modalTitle: 'Are you sure you want to delete this Scope 3 Category?',
        //modalSubtitle: 'This action cannot be undone.',
        btn: {
          title: 'Delete Scope 3 Category',
          style: { marginTop: 20 },
          type: 'submit',
        },
        tableName: 'scope3Categories',
      });
    }

    case 'marketFactors-table-add': {
      return (modalProps = {
        elements: [
          {
            component: 'select',
            placeholder: 'Source',
            name: 'source',
            label: 'Source',
            options: sources?.map((src) => ({
              value: src.id,
              label: src.source,
            })),
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Sub Entity',
            name: 'subentity',
            label: 'Sub Entity',
            options: _.sortBy(subentities?.map(itemToOptions), ['label']),
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Renewable',
            options: [
              { label: 'True', value: true },
              { label: 'False', value: false },
            ],
            name: 'renewable',
            label: 'Renewable',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'KG CO2E',
            name: 'kgco2e',
            label: 'KG CO2E',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'KG CO2E WTT',
            name: 'kgco2eWTT',
            label: 'KG CO2E WTT',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'TD',
            name: 'TD',
            label: 'TD',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'TD WTT',
            name: 'TDWTT',
            label: 'TD WTT',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Radioactive Waste',
            name: 'radioactiveWaste',
            label: 'Radioactive Waste',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Year',
            name: 'year',
            label: 'Year',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Outside of Scopes',
            name: 'outsideOfScopes',
            label: 'Outside of Scopes',
            value: '',
            onlyNumbers: true,
          },
          {
            type: 'text',
            placeholder: 'Factor Source',
            name: 'factorSource',
            label: 'Factor Source',
            value: '',
          },
        ],
        modalTitle: 'Add Market Factor',
        btn: {
          title: 'Add Market Factor',
          style: { marginTop: 5, backgroundColor: primaryButton },
        },
        tableName: 'marketFactors',
      });
    }

    case 'marketFactors-table-edit': {
      return (modalProps = {
        elements: [
          {
            component: 'select',
            placeholder: 'Source',
            name: 'source',
            label: 'Source',
            options: sources?.map((src) => ({
              value: src.id,
              label: src.source,
            })),
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Sub Entity',
            name: 'subentity',
            label: 'Sub Entity',
            options: _.sortBy(subentities?.map(itemToOptions), ['label']),
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Renewable',
            options: [
              { label: 'True', value: true },
              { label: 'False', value: false },
            ],
            name: 'renewable',
            label: 'Renewable',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'KG CO2E',
            name: 'kgco2e',
            label: 'KG CO2E',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'KG CO2E WTT',
            name: 'kgco2eWTT',
            label: 'KG CO2E WTT',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'TD',
            name: 'TD',
            label: 'TD',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'TD WTT',
            name: 'TDWTT',
            label: 'TD WTT',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Radioactive Waste',
            name: 'radioactiveWaste',
            label: 'Radioactive Waste',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Year',
            name: 'year',
            label: 'Year',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Outside of Scopes',
            name: 'outsideOfScopes',
            label: 'Outside of Scopes',
            value: '',
            onlyNumbers: true,
          },
          {
            type: 'text',
            placeholder: 'Factor Source',
            name: 'factorSource',
            label: 'Factor Source',
            value: '',
          },
        ],
        modalTitle: 'Edit Market Factor',
        btn: {
          title: 'Edit Market Factor',
          style: { marginTop: 5, backgroundColor: primaryButton },
        },
        tableName: 'marketFactors',
      });
    }

    case 'marketFactors-table-delete': {
      return (modalProps = {
        modalTitle: 'Are you sure you want to delete this market factor?',
        //modalSubtitle: 'This action cannot be undone.',
        btn: {
          title: 'Delete Market Factor',
          style: { marginTop: 20 },
          type: 'submit',
        },
        tableName: 'marketFactors',
      });
    }

    case 'entity-table-add': {
      return (modalProps = {
        elements: [
          {
            type: 'text',
            placeholder: 'Name',
            name: 'name',
            label: 'Name',
            value: '',
          },
          {
            component: 'markdownText',
            placeholder: 'Description',
            name: 'description',
            label: 'Description',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Metrics',
            options: metricList?.map(itemToOptions),
            name: 'metric',
            isMulti: true,
            label: 'Metrics',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Scope 3',
            options: scope3Categories?.map(itemToOptions),
            name: 'scope_3_cat',
            label: 'Scope 3',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Treat All as Scope 3',
            options: [
              { label: 'Yes', value: 'yes' },
              { label: 'No', value: 'no' },
            ],
            name: 'treat_scope_3',
            label: 'Treat All as Scope 3',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'SECR',
            options: [
              { label: 'Mandatory', value: 'Mandatory' },
              { label: 'Not Mandatory', value: 'Not Mandatory' },
            ],
            name: 'secr',
            label: 'SECR',
            value: '',
          },
        ],
        modalTitle: 'Add an Entity',
        btn: {
          title: 'Add an Entity',
          style: { marginTop: 5, backgroundColor: primaryButton },
        },
        tableName: 'entity',
      });
    }

    case 'entity-table-edit': {
      return (modalProps = {
        elements: [
          {
            type: 'text',
            placeholder: 'Name',
            name: 'name',
            label: 'Name',
            value: '',
          },
          {
            component: 'markdownText',
            placeholder: 'Description',
            name: 'description',
            label: 'Description',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Metrics',
            options: metricList?.map(itemToOptions),
            name: 'metric',
            isMulti: true,
            label: 'Metrics',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Scope 3',
            options: scope3Categories?.map(itemToOptions),
            name: 'scope_3_cat',
            label: 'Scope 3',
            isClearable: true,
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Treat All as Scope 3',
            options: [
              { label: 'Yes', value: 'yes' },
              { label: 'No', value: 'no' },
            ],
            name: 'treat_scope_3',
            label: 'Treat All as Scope 3',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'SECR',
            options: [
              { label: 'Mandatory', value: 'Mandatory' },
              { label: 'Not Mandatory', value: 'Not Mandatory' },
            ],
            name: 'secr',
            label: 'SECR',
            value: '',
          },
        ],
        modalTitle: 'Edit Entity',
        btn: {
          title: 'Edit Entity',
          style: { marginTop: 5, backgroundColor: primaryButton },
        },
        tableName: 'entity',
      });
    }

    case 'entity-table-delete': {
      return (modalProps = {
        modalTitle: 'Are you sure you want to delete this entity ?',
        //modalSubtitle: 'This action cannot be undone.',
        btn: {
          title: 'Delete Entity',
          style: { marginTop: 20 },
          type: 'submit',
        },
        tableName: 'entity',
      });
    }

    case 'subentity-table-add': {
      return (modalProps = {
        elements: [
          {
            type: 'text',
            placeholder: 'Name',
            name: 'name',
            label: 'Name',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Calc_market',
            options: [
              { label: 'True', value: true },
              { label: 'False', value: false },
            ],
            name: 'calc_market',
            label: 'Calc_market',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Description',
            name: 'description',
            label: 'Description',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Entities',
            options: entities?.map(itemToOptions),
            name: 'entity',
            isMulti: true,
            label: 'Entities',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Metrics',
            options: metricList?.map(itemToOptions),
            name: 'metric',
            isMulti: true,
            label: 'Metrics',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Hide In UI',
            options: [
              { label: 'True', value: true },
              { label: 'False', value: false },
            ],
            name: 'hideInUI',
            label: 'Hide From UI',
            value: '',
          },
        ],
        modalTitle: 'Add Sub Entity',
        btn: {
          title: 'Add Sub Entity',
          style: { marginTop: 5, backgroundColor: primaryButton },
        },
        tableName: 'subentity',
      });
    }

    case 'subentity-table-edit': {
      return (modalProps = {
        elements: [
          {
            type: 'text',
            placeholder: 'Name',
            name: 'name',
            label: 'Name',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Calc_market',
            options: [
              { label: 'True', value: true },
              { label: 'False', value: false },
            ],
            name: 'calc_market',
            label: 'Calc_market',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Description',
            name: 'description',
            label: 'Description',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Entities',
            options: entities?.map(itemToOptions),
            name: 'entity',
            isMulti: true,
            label: 'Entities',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Metrics',
            options: metricList?.map(itemToOptions),
            name: 'metric',
            isMulti: true,
            label: 'Metrics',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Hide In UI',
            options: [
              { label: 'True', value: true },
              { label: 'False', value: false },
            ],
            name: 'hideInUI',
            label: 'Hide From UI',
            value: '',
          },
        ],
        modalTitle: 'Edit Sub Entity',
        btn: {
          title: 'Edit Sub Entity',
          style: { marginTop: 5, backgroundColor: primaryButton },
        },
        tableName: 'subentity',
      });
    }

    case 'subentity-table-delete': {
      return (modalProps = {
        modalTitle: 'Are you sure you want to delete this  sub entity ?',
        //modalSubtitle: 'This action cannot be undone.',
        btn: {
          title: 'Delete Sub Entity',
          style: { marginTop: 20 },
          type: 'submit',
        },
        tableName: 'subentity',
      });
    }

    case `${optionType}-table-add`: {
      const elements = [
        {
          type: 'text',
          placeholder: 'Name',
          name: 'name',
          label: 'Name',
        },
        {
          type: 'textarea',
          placeholder: 'Description',
          name: 'description',
          label: 'Description',
        },
      ];

      if (optionType === 'price') {
        elements[0].name = 'priceType';
        elements[0].placeholder = 'Price Type';
      }

      return (modalProps = {
        elements: elements,
        modalTitle: `Add ${convertToTitleCase(optionType)} Type`,
        btn: {
          title: `Add ${convertToTitleCase(optionType)} Type`,
          style: { marginTop: 20, backgroundColor: primaryButton, border: 'none', color: textColor },
        },
        tableName: 'usageTypes',
      });
    }

    case `${optionType}-table-edit`: {
      const elements = [
        {
          type: 'text',
          placeholder: 'Name',
          name: 'name',
          label: 'Name',
        },
        {
          type: 'textarea',
          placeholder: 'Description',
          name: 'description',
          label: 'Description',
        },
      ];

      if (optionType === 'price') {
        elements[0].name = 'priceType';
        elements[0].placeholder = 'Price Type';
      }

      return (modalProps = {
        elements: elements,
        modalTitle: `Edit ${convertToTitleCase(optionType)} Type`,
        btn: {
          title: `Edit ${convertToTitleCase(optionType)} Type`,
          style: { marginTop: 20, backgroundColor: primaryButton, border: 'none', color: textColor },
        },
        tableName: 'usageTypes',
      });
    }

    case `${optionType}-table-delete`: {
      return (modalProps = {
        modalTitle: `Are you sure you want to delete this ${convertToTitleCase(optionType)} Type?`,
        modalSubtitle: 'This action cannot be undone.',
        btn: {
          title: `Delete ${convertToTitleCase(optionType)} Type`,
          style: { marginTop: 20 },
          type: 'submit',
        },
        tableName: 'usageTypes',
      });
    }

    case `metric-table-add`: {
      return (modalProps = {
        elements: [
          {
            type: 'text',
            placeholder: 'name',
            name: 'name',
            label: 'Name',
          },
          {
            type: 'textarea',
            placeholder: 'Description',
            name: 'description',
            label: 'Description',
          },
          {
            type: 'checkbox',
            placeholder: 'Is Spend',
            name: 'isSpend',
            label: 'Is Spend',
          },
          {
            component: 'select',
            placeholder: 'Currency',
            name: 'currencyId',
            label: 'Currency',
            isDisabled: true,
            isClearable: true,
            options: _.sortBy(
              currencies
                ?.filter((x) => x.hideInUI !== true)
                .map((x) => ({ label: `${x.symbol} (${x.isoCode})`, value: x.id })),
              ['label']
            ),
          },
        ],
        modalTitle: `Add Metric`,
        btn: {
          title: `Add Metric`,
          style: { marginTop: 20, backgroundColor: primaryButton, border: 'none', color: textColor },
        },
        tableName: 'metric',
      });
    }

    case `metric-table-edit`: {
      return (modalProps = {
        elements: [
          {
            type: 'text',
            placeholder: 'name',
            name: 'name',
            label: 'Name',
          },
          {
            type: 'textarea',
            placeholder: 'Description',
            name: 'description',
            label: 'Description',
          },
          {
            type: 'checkbox',
            placeholder: 'Is Spend',
            name: 'isSpend',
            label: 'Is Spend',
          },
          {
            component: 'select',
            placeholder: 'Currency',
            name: 'currencyId',
            label: 'Currency',
            isDisabled: true,
            isClearable: true,
            options: _.sortBy(
              currencies
                ?.filter((x) => x.hideInUI !== true)
                .map((x) => ({ label: `${x.symbol} (${x.isoCode})`, value: x.id })),
              ['label']
            ),
          },
        ],
        modalTitle: `Edit Metric`,
        btn: {
          title: `Edit Metric`,
          style: { marginTop: 20, backgroundColor: primaryButton, border: 'none', color: textColor },
        },
        tableName: 'metric',
      });
    }

    case `metric-table-delete`: {
      return (modalProps = {
        modalTitle: `Are you sure you want to delete this metric?`,
        modalSubtitle: 'This action cannot be undone.',
        btn: {
          title: `Delete metric`,
          style: { marginTop: 20 },
          type: 'submit',
        },
        tableName: 'metric',
      });
    }

    case 'countries-table-add': {
      return (modalProps = {
        elements: [
          {
            type: 'text',
            placeholder: 'Name',
            name: 'name',
            label: 'Name',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Country Alpha2 Code',
            name: 'countryAlpha2Code',
            label: 'CountryAlpha2Code',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Country Alpha3 Code',
            name: 'countryAlpha3Code',
            label: 'CountryAlpha3Code',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Country Numeric Code',
            name: 'numericCode',
            label: 'Numeric Code',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Latitude',
            name: 'latitude',
            label: 'Latitude',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Longitude',
            name: 'longitude',
            label: 'Longitude',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Capital',
            name: 'capital',
            label: 'Capital',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Default Airport',
            name: 'defaultAirport',
            label: 'Default Airport',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Continent',
            name: 'continent',
            label: 'Continent',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Council Region',
            name: 'councilRegion',
            label: 'Council Region',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Currency',
            options: currencies?.map((x) => ({ label: x.isoCode, value: x.id })),
            name: 'currency',
            label: 'Currency',
            value: '',
          },
        ],
        modalTitle: 'Add Country',
        btn: {
          title: 'Add Country',
          style: { marginTop: 5, backgroundColor: primaryButton },
        },
        tableName: 'countries',
      });
    }
    case 'countries-table-edit': {
      return (modalProps = {
        elements: [
          {
            type: 'text',
            placeholder: 'Name',
            name: 'name',
            label: 'Name',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Country Alpha2 Code',
            name: 'countryAlpha2Code',
            label: 'CountryAlpha2Code',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Country Alpha3 Code',
            name: 'countryAlpha3Code',
            label: 'CountryAlpha3Code',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Country Numeric Code',
            name: 'numericCode',
            label: 'Numeric Code',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Latitude',
            name: 'latitude',
            label: 'Latitude',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Longitude',
            name: 'longitude',
            label: 'Longitude',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Capital',
            name: 'capital',
            label: 'Capital',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Default Airport',
            name: 'defaultAirport',
            label: 'Default Airport',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Continent',
            name: 'continent',
            label: 'Continent',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Council Region',
            name: 'councilRegion',
            label: 'Council Region',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Currency',
            options: currencies?.map((x) => ({ label: x.isoCode, value: x.id })),
            name: 'currency',
            label: 'Currency',
            value: '',
          },
        ],
        modalTitle: 'Edit Country',
        btn: {
          title: 'Edit Country',
          style: { marginTop: 5, backgroundColor: primaryButton },
        },
        tableName: 'countries',
      });
    }

    case 'countries-table-delete': {
      return (modalProps = {
        modalTitle: 'Are you sure you want to delete this country?',
        //modalSubtitle: 'This action cannot be undone.',
        btn: {
          title: 'Delete Country',
          style: { marginTop: 20 },
          type: 'submit',
        },
        tableName: 'countries',
      });
    }

    case 'organisationMetrics-table-edit': {
      return (modalProps = {
        elements: [
          {
            component: 'select',
            placeholder: 'Organisation',
            options: _.sortBy(adminOrgs?.map(itemToOptions), ['label']),
            isSearchable: true,
            name: 'organisationId',
            label: 'Organisation',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Reporting Period',
            options: reportingPeriods,
            isSearchable: true,
            name: 'year',
            label: 'Reporting Period',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Metric 1',
            name: 'metric1',
            label: 'Metric 1',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Metric 2',
            name: 'metric2',
            label: 'Metric 1',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Metric 3',
            name: 'metric3',
            label: 'Metric 3',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Original Turnover',
            name: 'originalTurnover',
            label: 'Original Turnover',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Turnover',
            name: 'turnover',
            label: 'Turnover',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Status',
            options: [
              { label: 'Active', value: 'Active' },
              { label: 'Inactive', value: 'Inactive' },
            ],
            isSearchable: true,
            name: 'status',
            label: 'Status',
            value: '',
          },
        ],
        modalTitle: 'Edit Organisation Metric',
        btn: {
          title: 'Edit Organisation Metric',
          style: { marginTop: 20, backgroundColor: primaryButton, border: 'none', color: textColor },
        },
        tableName: 'organisationMetrics',
      });
    }

    case 'organisationMetrics-table-delete': {
      return (modalProps = {
        modalTitle: 'Are you sure you want to disable this Organisation Metric?',
        //modalSubtitle: 'This action cannot be undone.',
        btn: {
          title: 'Delete Organisation Metric',
          style: { marginTop: 20 },
          type: 'submit',
        },
        tableName: 'organisationMetrics',
      });
    }

    case 'help-table-add': {
      return (modalProps = {
        elements: [
          {
            type: 'text',
            placeholder: 'Title',
            name: 'title',
            label: 'Title',
            value: '',
          },
          {
            component: 'markdownText',
            placeholder: 'Message',
            name: 'content',
            label: 'Description',
            value: '',
          },
        ],
        modalTitle: 'Add Help',
        btn: {
          title: 'Add Help',
          style: { marginTop: 20, backgroundColor: primaryButton, border: 'none', color: textColor },
        },
        tableName: 'help',
      });
    }

    case 'help-table-edit': {
      return (modalProps = {
        elements: [
          {
            type: 'text',
            placeholder: 'Title',
            name: 'title',
            label: 'Title',
            value: '',
          },
          {
            component: 'markdownText',
            placeholder: 'Message',
            name: 'content',
            label: 'Description',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Handle',
            name: 'handle',
            label: 'Handle',
            value: '',
          },
        ],
        modalTitle: 'Edit Help',
        btn: {
          title: 'Edit Help',
          style: { marginTop: 20, backgroundColor: primaryButton, border: 'none', color: textColor },
        },
        tableName: 'help',
      });
    }

    case 'help-table-delete': {
      return (modalProps = {
        modalTitle: 'Are you sure you want to delete this Content?',
        modalSubtitle: 'This Action cannot be undone.',
        btn: {
          title: 'Delete Help Content',
          style: { marginTop: 20 },
          type: 'submit',
        },
        tableName: 'help',
      });
    }

    case 'orgs-table-recalcOrgUsage': {
      return (modalProps = {
        modalTitle: 'Are you sure you want to recalculate this usage?',
        btn: {
          title: 'Recalculate usage',
          style: { marginTop: 20 },
          type: 'submit',
        },
        tableName: 'organisation',
      });
    }

    case 'recalcAllUsage': {
      return (modalProps = {
        modalTitle: 'Are you sure you want to recalculate the usage of ALL organisations?',
        btn: {
          title: 'Recalculate usage',
          style: { marginTop: 20 },
          type: 'submit',
        },
        tableName: 'recalcAllUsage',
      });
    }

    case 'orgs-table-delete': {
      return (modalProps = {
        modalTitle: 'Are you sure you want to delete this Organisation?',
        modalSubtitle: 'This Action cannot be undone.',
        btn: {
          title: 'Delete Organisation',
          style: { marginTop: 20 },
          type: 'submit',
        },
        tableName: 'organisation',
      });
    }

    case 'profile-table-edit': {
      return (modalProps = {
        elements: [
          {
            type: 'text',
            placeholder: 'First Name',
            name: 'firstName',
            label: 'First Name',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Last Name',
            name: 'lastName',
            label: 'Last Name',
            value: '',
          },
          {
            type: 'email',
            placeholder: 'Email',
            name: 'email',
            label: 'Email',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Consultant Admin',
            options: _.sortBy(consultantAdminOrgs?.map(itemToOptions), ['label']),
            name: 'consultantAdmin',
            label: 'Consultant Admin',
            isClearable: true,
            value: '',
          },
          {
            component: 'select',
            placeholder: 'White Label Admin',
            options: _.sortBy(whiteLabelAdminOrgs?.map(itemToOptions), ['label']),
            name: 'whiteLabelAdmin',
            label: 'White Label Admin',
            isClearable: true,
            value: '',
          },
        ],
        modalTitle: 'Edit User',
        btn: {
          title: 'Edit Profile',
          style: { marginTop: 20, backgroundColor: primaryButton, border: 'none', color: textColor },
        },
        tableName: 'profile',
      });
    }

    case 'facilities-table-edit': {
      return (modalProps = {
        elements: [
          {
            type: 'text',
            placeholder: 'Name',
            name: 'name',
            label: 'Name',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Organisation',
            options: _.sortBy(adminOrgs?.map(itemToOptions), ['label']),
            isSearchable: true,
            name: 'organisationId',
            label: 'Organisation',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Primary Facility',
            options: [
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ],
            name: 'isPrimary',
            label: 'Primary Facility',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Facility Type',
            options: _.sortBy(types?.map(itemToOptions), ['label']),
            name: 'facilityTypeId',
            label: 'Facility Type',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Post Code',
            name: 'postCode',
            label: 'Post Code',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'External ID',
            name: 'externalId',
            label: 'External ID',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Country',
            options: _.sortBy(state.organisation?.countries?.map(itemToOptions), ['label']),
            name: 'country',
            label: 'Country',
            value: '',
          },
        ],
        modalTitle: 'Edit Facility',
        btn: {
          title: 'Edit Facility',
          style: { marginTop: 20, backgroundColor: primaryButton, border: 'none', color: textColor },
        },
        tableName: 'facilities',
      });
    }

    case 'facilities-table-delete': {
      return (modalProps = {
        modalTitle: 'Are you sure you want to disable this facility?',
        modalSubtitle: 'This action cannot be undone.',
        btn: {
          title: 'Delete Facility',
          style: { marginTop: 20 },
          type: 'submit',
        },
        tableName: 'facilities',
      });
    }

    case 'see-terms-and-conditions': {
      return (modalProps = {
        modalTitle: 'Terms and Conditions',
        modalSubtitle: 'Test',
        btn: {
          title: 'Add an Entity',
          style: { marginTop: 5, backgroundColor: primaryButton },
        },
      });
    }

    case 'create-terms-and-conditions': {
      return (modalProps = {
        elements: [
          {
            component: 'markdownText',
            placeholder: 'T&C',
            name: 'TermsAndConditions',
            label: 'Description',
            value: '',
          },
        ],
        modalTitle: 'Custom Terms and Conditions',
        btn: {
          title: 'Add an Entity',
          style: { marginTop: 5, backgroundColor: primaryButton },
        },
      });
    }

    case 'see-software-license-agreement': {
      return (modalProps = {
        elements: [
          {
            component: 'markdownText',
            placeholder: 'Software License Agreement',
            name: 'SoftwareLicenseAgreement',
            label: 'Description',
            value: '',
          },
        ],
        modalTitle: 'Software License Agreement',
        btn: {
          title: 'Add an Entity',
          style: { marginTop: 5, backgroundColor: primaryButton },
        },
      });
    }

    case 'create-software-license-agreement': {
      return (modalProps = {
        elements: [
          {
            component: 'markdownText',
            placeholder: 'Software License Agreement',
            name: 'SoftwareLicenseAgreement',
            label: 'Description',
            value: '',
          },
        ],
        modalTitle: 'Custom Software License Agreement',
        btn: {
          title: 'Add an Entity',
          style: { marginTop: 5, backgroundColor: primaryButton },
        },
      });
    }

    case 'see-privacy': {
      return (modalProps = {
        elements: [
          {
            component: 'markdownText',
            placeholder: 'Privacy',
            name: 'Privacy',
            label: 'Description',
            value: '',
          },
        ],
        modalTitle: 'Privacy',
        btn: {
          title: 'Add an Entity',
          style: { marginTop: 5, backgroundColor: primaryButton },
        },
      });
    }

    case 'create-privacy': {
      return (modalProps = {
        elements: [
          {
            component: 'markdownText',
            placeholder: 'Privacy',
            name: 'Privacy',
            label: 'Description',
            value: '',
          },
        ],
        modalTitle: 'Privacy',
        btn: {
          title: 'Add an Entity',
          style: { marginTop: 5, backgroundColor: primaryButton },
        },
      });
    }

    case 'sea-distances-table-add': {
      return (modalProps = {
        elements: [
          {
            type: 'text',
            placeholder: 'Origin Country',
            name: 'originCountry',
            label: 'Origin Country',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Origin Country Latitude',
            name: 'originLat',
            label: 'Origin Country Latitude',
            value: '',
            onlyNumbers: true,
          },
          {
            type: 'text',
            placeholder: 'Origin Country Longitude',
            name: 'originLon',
            label: 'Origin Country Longitude',
            value: '',
            onlyNumbers: true,
          },
          {
            type: 'text',
            placeholder: 'Origin Port Name',
            name: 'originPortName',
            label: 'Origin Port Name',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Origin Port Long Code',
            name: 'originLongCode',
            label: 'Origin Port Long Code',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Destination Country',
            name: 'destinationCountry',
            label: 'Destination Country',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Destination Country Latitude',
            name: 'destinationLat',
            label: 'Destination Country Latitude',
            value: '',
            onlyNumbers: true,
          },
          {
            type: 'text',
            placeholder: 'Destination Country Longitude',
            name: 'destinationLon',
            label: 'Destination Country Longitude',
            value: '',
            onlyNumbers: true,
          },
          {
            type: 'text',
            placeholder: 'Destination Port Name',
            name: 'destinationPortName',
            label: 'Destination Port Name',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Destination Port Long Code',
            name: 'destinationPortLongCode',
            label: 'Destination Port Long Code',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Distance In Kilometers (Km)',
            name: 'distanceKm',
            label: 'Distance In Kilometers (Km)',
            value: '',
            onlyNumbers: true,
          },
          {
            type: 'text',
            placeholder: 'Distance In Miles',
            name: 'distanceMiles',
            label: 'Distance In Miles',
            value: '',
            onlyNumbers: true,
          },
        ],
        modalTitle: 'Add Sea Distance',
        btn: {
          title: 'Add Sea Distance',
          style: { marginTop: 20, backgroundColor: primaryButton, border: 'none', color: textColor },
        },
        tableName: 'sea-distances',
      });
    }

    case 'sea-distances-table-edit': {
      return (modalProps = {
        elements: [
          {
            type: 'text',
            placeholder: 'Origin Country',
            name: 'originCountry',
            label: 'Origin Country',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Origin Country Latitude',
            name: 'originLat',
            label: 'Origin Country Latitude',
            value: '',
            onlyNumbers: true,
          },
          {
            type: 'text',
            placeholder: 'Origin Country Longitude',
            name: 'originLon',
            label: 'Origin Country Longitude',
            value: '',
            onlyNumbers: true,
          },
          {
            type: 'text',
            placeholder: 'Origin Port Name',
            name: 'originPortName',
            label: 'Origin Port Name',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Origin Port Long Code',
            name: 'originLongCode',
            label: 'Origin Port Long Code',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Destination Country',
            name: 'destinationCountry',
            label: 'Destination Country',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Destination Country Latitude',
            name: 'destinationLat',
            label: 'Destination Country Latitude',
            value: '',
            onlyNumbers: true,
          },
          {
            type: 'text',
            placeholder: 'Destination Country Longitude',
            name: 'destinationLon',
            label: 'Destination Country Longitude',
            value: '',
            onlyNumbers: true,
          },
          {
            type: 'text',
            placeholder: 'Destination Port Name',
            name: 'destinationPortName',
            label: 'Destination Port Name',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Destination Port Long Code',
            name: 'destinationPortLongCode',
            label: 'Destination Port Long Code',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Distance In Kilometers (Km)',
            name: 'distanceKm',
            label: 'Distance In Kilometers (Km)',
            value: '',
            onlyNumbers: true,
          },
          {
            type: 'text',
            placeholder: 'Distance In Miles',
            name: 'distanceMiles',
            label: 'Distance In Miles',
            value: '',
            onlyNumbers: true,
          },
        ],
        modalTitle: 'Edit Sea Distance',
        btn: {
          title: 'Edit Sea Distance',
          style: { marginTop: 20, backgroundColor: primaryButton, border: 'none', color: textColor },
        },
        tableName: 'sea-distances',
      });
    }

    case 'sea-distances-table-delete': {
      return (modalProps = {
        modalTitle: 'Are you sure you want to delete this sea distance?',
        modalSubtitle: 'This action cannot be undone.',
        btn: {
          title: 'Delete Sea Distance',
          style: { marginTop: 20 },
          type: 'submit',
        },
        tableName: 'sea-distances',
      });
    }

    case 'airport-table-add': {
      return (modalProps = {
        elements: [
          {
            type: 'text',
            placeholder: 'Code',
            name: 'code',
            label: 'Code',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Name',
            name: 'name',
            label: 'Name',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Municipality',
            name: 'municipality',
            label: 'Municipality',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Country',
            name: 'country',
            label: 'Country',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'ISO Region',
            name: 'isoRegion',
            label: 'ISO Region',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Latitude',
            name: 'lat',
            label: 'Latitude',
            value: '',
            onlyNumbers: true,
          },
          {
            type: 'text',
            placeholder: 'Longitude',
            name: 'lng',
            label: 'Longitude',
            value: '',
            onlyNumbers: true,
          },
        ],
        modalTitle: 'Add an Airport',
        btn: {
          title: 'Add an Airport',
          style: { marginTop: 20, backgroundColor: primaryButton, border: 'none', color: textColor },
        },
        tableName: 'airport',
      });
    }

    case 'airport-table-edit': {
      return (modalProps = {
        elements: [
          {
            type: 'text',
            placeholder: 'Code',
            name: 'code',
            label: 'Code',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Name',
            name: 'name',
            label: 'Name',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Municipality',
            name: 'municipality',
            label: 'Municipality',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Country',
            name: 'country',
            label: 'Country',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'ISO Region',
            name: 'isoRegion',
            label: 'ISO Region',
            value: '',
          },
          {
            type: 'number',
            placeholder: 'Latitude',
            name: 'lat',
            label: 'Latitude',
            value: '',
            onlyNumbers: true,
          },
          {
            type: 'number',
            placeholder: 'Longitude',
            name: 'lng',
            label: 'Longitude',
            value: '',
            onlyNumbers: true,
          },
        ],
        modalTitle: 'Edit an Airport',
        btn: {
          title: 'Edit an Airport',
          style: { marginTop: 20, backgroundColor: primaryButton, border: 'none', color: textColor },
        },
        tableName: 'airport',
      });
    }

    case 'airport-table-delete': {
      return (modalProps = {
        modalTitle: 'Are you sure you want to delete this airport?',
        modalSubtitle: 'This action cannot be undone.',
        btn: {
          title: 'Delete Airport',
          style: { marginTop: 20 },
          type: 'submit',
        },
        tableName: 'airport',
      });
    }

    case 'disconnect-xeroOrg': {
      return (modalProps = {
        modalTitle: 'Are you sure you want to disconnect this organisation from Xero?',
        modalSubtitle: 'This action cannot be undone.',
        btn: {
          title: 'Disconnect Organisation',
          style: { marginTop: 20 },
          type: 'submit',
        },
        table: 'disconnect',
        type: 'xero',
      });
    }

    case 'disconnect-sage': {
      return (modalProps = {
        modalTitle: 'Are you sure you want to disconnect this organisation from Sage?',
        modalSubtitle: 'This action cannot be undone.',
        btn: {
          title: 'Disconnect Organisation',
          style: { marginTop: 20 },
          type: 'submit',
        },
        table: 'disconnect',
        type: 'sage',
      });
    }

    case 'saveXero-settings': {
      const methods = ['Account Codes', 'Tracking Categories'];

      //switching methods
      const fromMethod = savedMethod === 'accountcodes' ? methods[0] : methods[1];
      const toMethod = savedMethod === 'accountcodes' ? methods[1] : methods[0];
      const switchMethodSubtitle = `Warning: by switching from ${fromMethod} to ${toMethod}, your previous mapping will be overwritten, and you will need to re-map your carbon categories.`;

      const switchCategorySubtitle = `Warning: by switching from ${savedCategory?.label} to ${selectedTrackingCategory?.label}, your previous mapping will be overwritten, and you will need to re-map your carbon categories.`;

      return (modalProps = {
        modalTitle: 'Please confirm your new Xero settings.',
        modalSubtitle:
          savedMethod && savedMethod !== method
            ? switchMethodSubtitle
            : savedCategory && savedCategory !== selectedTrackingCategory?.label
            ? switchCategorySubtitle
            : null,
        btn: {
          title: 'Save Settings',
          style: { marginTop: 20 },
          type: 'submit',
        },
        table: 'saveXero',
        type: 'xero',
      });
    }

    case 'saveXero-bulkOptions': {
      const tenantName = tenants?.find((item) => item.tenantId === tenant)?.tenantName;

      return (modalProps = {
        modalTitle: `Please confirm you wish to bulk add these tracking options under ${tenantName} for Tracking Category: ${selectedTrackingCategory?.label}`,
        modalSubtitle:
          'Please note, this action will add a tracking option in Xero for each spend entity and overwrite any existing settings.',
        btn: {
          title: 'Confirm',
          style: { marginTop: 20 },
          type: 'submit',
        },
        type: 'bulkAddXeroOptions',
      });
    }

    case 'saveXero-tenant': {
      const { tenant: newTenant } = item || {};

      //switching tenants

      const fromTenant = tenants?.find((item) => item.tenantId === tenant);
      const toTenant = tenants?.find((item) => newTenant === item.tenantId);
      const modalSubtitle = `Warning: by switching from ${fromTenant?.tenantName} to ${toTenant?.tenantName}, any settings under ${fromTenant?.tenantName} will be cleared, and you will need to re-map your carbon categories.`;

      return (modalProps = {
        modalTitle: 'Please confirm your new Xero organisation.',
        modalSubtitle: modalSubtitle,
        btn: {
          title: 'Confirm',
          style: { marginTop: 20 },
          type: 'submit',
        },
        table: 'saveXeroTenant',
        type: 'xero',
      });
    }

    case 'benchmark-table-add': {
      return (modalProps = {
        elements: [
          {
            component: 'select',
            placeholder: 'Organisation',
            options: _.sortBy(adminOrgs?.map(itemToOptions), ['label']),
            isSearchable: true,
            name: 'org',
            label: 'Organisation',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Scope 1',
            name: 'scope1',
            label: 'Scope 1',
            value: '',
            onlyNumbers: true,
          },
          {
            type: 'text',
            placeholder: 'Scope 2',
            name: 'scope2',
            label: 'Scope 2',
            value: '',
            onlyNumbers: true,
          },
          {
            type: 'text',
            placeholder: 'Scope 3',
            name: 'scope3',
            label: 'Scope 3',
            value: '',
            onlyNumbers: true,
          },
          {
            type: 'text',
            placeholder: 'Scope 1 & 2 perm',
            name: 'scope1and2perm',
            label: 'Scope 1 & 2 perm',
            value: '',
            onlyNumbers: true,
          },
          {
            type: 'text',
            placeholder: 'Scope 1 & 2 per m2',
            name: 'scope1and2perm2',
            label: 'Scope 1 & 2 per m2',
            value: '',
            onlyNumbers: true,
          },
          {
            type: 'text',
            placeholder: 'Scope 1 & 2 per fte',
            name: 'scope1and2perfte',
            label: 'Scope 1 & 2 per fte',
            value: '',
            onlyNumbers: true,
          },
          {
            type: 'text',
            placeholder: 'Scope 3 Cat',
            name: 'scope3cat',
            label: 'Scope 3 Cat',
            value: '',
            onlyNumbers: true,
          },
          {
            type: 'text',
            placeholder: 'Scope 3 Perm',
            name: 'scope3perm',
            label: 'Scope 3 Perm',
            value: '',
            onlyNumbers: true,
          },
          {
            type: 'text',
            placeholder: 'Year',
            name: 'year',
            label: 'Year',
            value: '',
            onlyNumbers: true,
          },
        ],
        modalTitle: 'Add Benchmark Data',
        btn: {
          title: 'Add Benchmark',
          style: { marginTop: 20, backgroundColor: primaryButton, border: 'none', color: textColor },
        },
        tableName: 'benchmark',
      });
    }

    case 'benchmark-table-edit': {
      return (modalProps = {
        elements: [
          {
            component: 'select',
            placeholder: 'Organisation',
            options: _.sortBy(adminOrgs?.map(itemToOptions), ['label']),
            isSearchable: true,
            name: 'org',
            label: 'Organisation',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Scope 1',
            name: 'scope1',
            label: 'Scope 1',
            value: '',
            onlyNumbers: true,
          },
          {
            type: 'text',
            placeholder: 'Scope 2',
            name: 'scope2',
            label: 'Scope 2',
            value: '',
            onlyNumbers: true,
          },
          {
            type: 'text',
            placeholder: 'Scope 3',
            name: 'scope3',
            label: 'Scope 3',
            value: '',
            onlyNumbers: true,
          },
          {
            type: 'text',
            placeholder: 'Scope 1 & 2 perm',
            name: 'scope1and2perm',
            label: 'Scope 1 & 2 perm',
            value: '',
            onlyNumbers: true,
          },
          {
            type: 'text',
            placeholder: 'Scope 1 & 2 per m2',
            name: 'scope1and2perm2',
            label: 'Scope 1 & 2 per m2',
            value: '',
            onlyNumbers: true,
          },
          {
            type: 'text',
            placeholder: 'Scope 1 & 2 per FTE',
            name: 'scope1and2perfte',
            label: 'Scope 1 & 2 per FTE',
            value: '',
            onlyNumbers: true,
          },
          {
            type: 'text',
            placeholder: 'Scope 3 Cat',
            name: 'scope3cat',
            label: 'Scope 3 Cat',
            value: '',
            onlyNumbers: true,
          },
          {
            type: 'text',
            placeholder: 'Scope 3 Perm',
            name: 'scope3perm',
            label: 'Scope 3 Perm',
            value: '',
            onlyNumbers: true,
          },
          {
            type: 'text',
            placeholder: 'Year',
            name: 'year',
            label: 'Year',
            value: '',
            onlyNumbers: true,
          },
        ],
        modalTitle: 'Edit Benchmark Data',
        btn: {
          title: 'Edit Benchmark',
          style: { marginTop: 20, backgroundColor: primaryButton, border: 'none', color: textColor },
        },
        tableName: 'benchmark',
      });
    }

    case 'benchmark-table-delete': {
      return (modalProps = {
        modalTitle: 'Are you sure you want to delete this benchmark data?',
        modalSubtitle: 'This action cannot be undone.',
        btn: {
          title: 'Delete Benchmark data',
          style: { marginTop: 20 },
          type: 'submit',
        },
        tableName: 'benchmark',
      });
    }

    case 'pricingBand-table-delete': {
      return (modalProps = {
        modalTitle: 'Are you sure you want to delete this pricing band',
        modalSubtitle: 'This action cannot be undone.',
        btn: {
          title: 'Delete Pricing Band',
          style: { marginTop: 20 },
          type: 'submit',
        },
        tableName: 'pricingBands',
      });
    }

    case 'disableOrgReports-table-disable': {
      const adminOrg = item.id === item.whiteLabelId || item.id === item.consultantId;
      const title =
        cyfAdmin && adminOrg
          ? 'Are you sure you want to disable the ability to add reports for this organisation and any others belonging to the same Consultant/White Label?'
          : 'Are you sure you want to disable the ability to add reports for this organisation?';
      return (modalProps = {
        modalTitle: title,
        btn: {
          title: 'Disable Report Generation',
          style: { marginTop: 20 },
          type: 'submit',
        },
        tableName: 'organisation',
      });
    }

    case 'enableOrgReports-table-disable': {
      const adminOrg = item.id === item.whiteLabelId || item.id === item.consultantId;
      const title =
        cyfAdmin && adminOrg
          ? 'Are you sure you want to enable the ability to add reports for this organisation and any others belonging to the same Consultant/White Label?'
          : 'Are you sure you want to enable the ability to add reports for this organisation?';
      return (modalProps = {
        modalTitle: title,
        btn: {
          title: 'Enable Report Generation',
          style: { marginTop: 20 },
          type: 'submit',
        },
        tableName: 'organisation',
      });
    }

    case 'excludeOrgFromDeletion-table-enable': {
      return (modalProps = {
        modalTitle: 'Are you sure you want to exclude this organisation from deletion?',
        btn: {
          title: 'Yes',
          style: { marginTop: 20 },
          type: 'submit',
        },
        tableName: 'organisation',
      });
    }

    case 'excludeOrgFromDeletion-table-disable': {
      return (modalProps = {
        modalTitle: 'Are you sure you want to include this organisation on deletion?',
        btn: {
          title: 'Yes',
          style: { marginTop: 20 },
          type: 'submit',
        },
        tableName: 'organisation',
      });
    }

    case 'enableAPIAccess-table-disable': {
      return (modalProps = {
        modalTitle: 'Are you sure you want to enable API access for this organisation?',
        btn: {
          title: 'Enable API Access',
          style: { marginTop: 20 },
          type: 'submit',
        },
        tableName: 'organisation',
      });
    }

    case 'disableAPIAccess-table-disable': {
      return (modalProps = {
        modalTitle: 'Are you sure you want to disable API access for this organisation?',
        btn: {
          title: 'Disable API Access',
          style: { marginTop: 20 },
          type: 'submit',
        },
        tableName: 'organisation',
      });
    }

    case 'reports-disabled': {
      return (modalProps = {
        modalTitle: 'Reports are disabled!',
        modalSubtitle: 'Please contact your administrator to run a new report.',
      });
    }

    case 'disableOrgCreation-table-disable': {
      const adminOrg = item.id === item.whiteLabelId || item.id === item.consultantId;
      const title =
        cyfAdmin && adminOrg
          ? 'Are you sure you want to disable the ability to add an organisation for this organisation and any others belonging to the same Consultant/White Label?'
          : 'Are you sure you want to disable the ability to add an organisation for this organisation?';
      return (modalProps = {
        modalTitle: title,
        btn: {
          title: 'Disable Organisation Creation',
          style: { marginTop: 20 },
          type: 'submit',
        },
        tableName: 'organisation',
      });
    }

    case 'enableOrgCreation-table-disable': {
      const adminOrg = item.id === item.whiteLabelId || item.id === item.consultantId;
      const title =
        cyfAdmin && adminOrg
          ? 'Are you sure you want to enable the ability to add an organisation for this organisation and any others belonging to the same Consultant/White Label?'
          : 'Are you sure you want to enable the ability to add an organisation for this organisation?';
      return (modalProps = {
        modalTitle: title,
        btn: {
          title: 'Enable Organisation Creation',
          style: { marginTop: 20 },
          type: 'submit',
        },
        tableName: 'organisation',
      });
    }

    case 'organisationCreation-disabled': {
      return (modalProps = {
        modalTitle: 'Organisation Creation is disabled!',
        modalSubtitle: 'Please contact your administrator to create a new organisation.',
      });
    }

    case 'deleteOrgs': {
      return (modalProps = {
        modalTitle: 'Are you sure you want to upload the file to delete organisations?',
        modalSubtitle: 'This action cannot be undone.',
        extraInfo: {
          action: 'downloadLink',
          show: true,
          title: 'Download Template',
          link: `https://s3.eu-west-2.amazonaws.com/downloads.compareyourfootprint.com/orgDeletion_template.csv`,
          icon: <i className='fa-solid fa-file-csv' />,
        },
        btn: {
          title: 'Upload File',
          style: { marginTop: 20 },
          type: 'submit',
        },
        tableName: 'organisation',
      });
    }

    case 'show-report-organisations': {
      const orgsInReport = organisations?.filter((org) => includedOrganisations.includes(org.id));
      return (modalProps = {
        modalTitle: 'Organisations Linked to this Report',
        elements: [
          {
            type: 'list',
            listItems: orgsInReport.map((org) => org.name),
          },
        ],
      });
    }

    case 'importMixedTemplates': {
      return (modalProps = {
        modalTitle: 'Import Mixed Template',
        modalSubtitle: 'Upload your template or download one and fill in as needed.',
        extraInfo: {
          action: 'downloadLink',
          show: true,
          title: 'Download Template',
          link: `https://s3.eu-west-2.amazonaws.com/downloads.compareyourfootprint.com/mixed_template.xlsx`,
          icon: <i className='fa-solid fa-file-csv' />,
        },
        btn: {
          title: 'Upload File',
          style: { marginTop: 20 },
          type: 'submit',
        },
        tableName: 'organisation',
      });
    }

    case 'show-addOns-organisations': {
      const orgsLinkedToAddOn = adminOrgs?.filter((org) => org?.addOns?.includes(item.id));
      return (modalProps = {
        elements: [
          {
            type: 'table',
            columns: ['id', 'name'],
            data: orgsLinkedToAddOn,
          },
        ],
        modalTitle: 'Organisations Linked to this Add-On',
        filters: [
          {
            name: 'id',
            type: 'select',
            placeholder: 'Organisation',
            options: orgsLinkedToAddOn.map(itemToOptions),
            isClearable: true,
          },
        ],
      });
    }

    case 'show-features-organisations': {
      const orgsLinkedToFeature = adminOrgs?.filter((org) => org?.features?.includes(item?.featureKey));
      return (modalProps = {
        elements: [
          {
            type: 'table',
            columns: ['id', 'name'],
            data: orgsLinkedToFeature,
          },
        ],
        modalTitle: 'Organisations Linked to this Feature',
        filters: [
          {
            name: 'id',
            type: 'select',
            placeholder: 'Organisation',
            options: orgsLinkedToFeature.map(itemToOptions),
            isClearable: true,
          },
        ],
      });
    }

    case 'show-addOns-conversionFactors': {
      return (modalProps = {
        elements: [
          {
            type: 'table',
            columns: [
              'id',
              'year',
              'subentity',
              'scope',
              'metric',
              'seat',
              'vehicle',
              'fuel',
              'production',
              'load',
              'waste',
              'price',
              'originlocation',
              'finallocation',
            ],
            data: state?.admin?.termsItems?.items,
          },
        ],
        modalTitle: 'Conversion Factors Linked to this Add-On',
        modalSubtitle: 'Only the first 1000 conversion factors are being shown on the modal.',
        filters: [
          { name: 'year', type: 'select', placeholder: 'Year', options: yearOptions, isClearable: true },
          {
            name: 'subentity',
            type: 'select',
            placeholder: 'Subentity',
            options: _.sortBy(subentities?.map(itemToOptions), ['label']),
            isClearable: true,
          },
        ],
      });
    }

    case 'addOns-table-edit': {
      return (modalProps = {
        elements: [
          {
            type: 'text',
            label: 'Name',
            name: 'name',
          },
          {
            type: 'text',
            label: 'Source',
            name: 'source',
          },
          {
            component: 'select',
            label: 'Private Add On',
            name: 'isPrivate',
            options: [
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ],
          },
          {
            component: 'select',
            label: 'Owner',
            name: 'owner',
            options: _.sortBy(adminOrgs?.map(itemToOptions), ['label']),
          },
          {
            type: 'text',
            name: 'creator',
            label: 'Created By',
            placeholder: user,
            disabled: true,
            value: item?.creator,
          },
        ],
        modalTitle: 'Edit Add On',
        btn: {
          title: 'Edit Add On',
          style: { marginTop: 20, backgroundColor: primaryButton, border: 'none', color: textColor },
        },
        tableName: 'addOns',
      });
    }

    case 'addOns-table-add': {
      return (modalProps = {
        modalTitle: 'Add Add On',
        elements: [
          {
            type: 'text',
            label: 'Name',
            name: 'name',
          },
          {
            type: 'text',
            label: 'Source',
            name: 'source',
          },
          {
            component: 'select',
            label: 'Private Add On',
            name: 'isPrivate',
            options: [
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ],
          },
          {
            component: 'select',
            label: 'Owner',
            name: 'owner',
            options: _.sortBy(adminOrgs?.map(itemToOptions), ['label']),
          },
          {
            type: 'text',
            name: 'creator',
            label: 'Created By',
            placeholder: user,
            disabled: true,
            value: item?.creator,
          },
        ],
        btn: {
          title: 'Add Add On',
          style: { marginTop: 20, backgroundColor: primaryButton, border: 'none', color: textColor },
          type: 'submit',
        },
        tableName: 'addOns',
      });
    }

    case 'addOns-table-delete': {
      return (modalProps = {
        modalTitle: 'Are you sure you want to delete this add on?',
        modalSubtitle: 'This action cannot be undone.',
        btn: {
          title: 'Delete Add On',
          style: { marginTop: 20 },
          type: 'submit',
        },
        tableName: 'addOns',
      });
    }

    case 'add-addOns-to-Organisation': {
      return (modalProps = {
        wrapperStyle: { overflow: 'unset' },
        modalTitle: 'Are you sure you want to add this add on to an organisation?',
        elements: [
          {
            component: 'select',
            placeholder: 'Organisation',
            options: _.sortBy(adminOrgs?.map(itemToOptions), ['label']),
            isSearchable: true,
            name: 'organisation',
            label: 'Organisation',
            value: '',
            //isMulti: true,
          },
        ],
        btn: {
          title: 'Add Add On',
          style: { marginTop: 20 },
          type: 'submit',
        },
        tableName: 'organisation',
      });
    }

    case 'add-features-to-Organisation': {
      return (modalProps = {
        wrapperStyle: { overflow: 'unset' },
        modalTitle: 'Are you sure you want to add this feature to an organisation?',
        elements: [
          {
            component: 'select',
            placeholder: 'Organisation',
            options: _.sortBy(adminOrgs?.map(itemToOptions), ['label']),
            isSearchable: true,
            name: 'organisation',
            label: 'Organisation',
            value: '',
            //isMulti: true,
          },
        ],
        btn: {
          title: 'Add Feature',
          style: { marginTop: 20 },
          type: 'submit',
        },
        tableName: 'organisation',
      });
    }

    case 'industrySubcategory-table-add': {
      return (modalProps = {
        elements: [
          {
            component: 'select',
            placeholder: 'Industry',
            name: 'industry',
            label: 'Industry',
            options: industries.filter((i) => i.include === true).map(itemToOptions),
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Name',
            name: 'name',
            label: 'Name',
            value: '',
          },
        ],
        modalTitle: 'Add an Industry Sub-Category',
        btn: {
          title: 'Add Sub-Category',
          style: { marginTop: 20, backgroundColor: primaryButton, border: 'none', color: textColor },
        },
        tableName: 'industrySubcategory',
      });
    }

    case 'industrySubcategory-table-edit': {
      return (modalProps = {
        elements: [
          {
            component: 'select',
            placeholder: 'Industry',
            name: 'industry',
            label: 'Industry',
            options: industries.filter((i) => i.include === true).map(itemToOptions),
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Name',
            name: 'name',
            label: 'Name',
            value: '',
          },
        ],
        modalTitle: 'Edit an Industry Sub-Category',
        btn: {
          title: 'Edit Sub-Category',
          style: { marginTop: 20, backgroundColor: primaryButton, border: 'none', color: textColor },
        },
        tableName: 'industrySubcategory',
      });
    }

    case 'industrySubcategory-table-delete': {
      return (modalProps = {
        modalTitle: 'Are you sure you want to delete this Sub-Category',
        modalSubtitle: 'This action cannot be undone.',
        btn: {
          title: 'Delete Industry Sub-Category',
          style: { marginTop: 20 },
          type: 'submit',
        },
        tableName: 'industrySubcategory',
      });
    }

    case 'confirm-contract-execution': {
      return (modalProps = {
        modalTitle: 'Are you sure you want to execute this contract?',
        btn: {
          title: 'Confirm',
          style: { marginTop: 20 },
          type: 'submit',
        },
        tableName: 'contract',
      });
    }

    case 'confirm-migrate-to-whiteLabel': {
      return (modalProps = {
        modalTitle: 'Are you sure you want to migrate this organisation?',
        modalSubtitle: 'By confirming you are migrating all data from this organisation to become a White Label account.',
        btn: {
          title: 'Confirm',
          style: { marginTop: 20 },
          type: 'submit',
        },
        tableName: 'contract',
      });
    }

    case 'usageTypeDetails-table-add': {
      return (modalProps = {
        elements: [
          {
            type: 'text',
            placeholder: 'Display name',
            name: 'displayName',
            label: 'Display Name',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Subentity',
            options: _.sortBy(subentities?.map(itemToOptions), ['label']),
            name: 'subentity',
            label: 'Subentity (If left empty applies to All)',
            value: null,
            isClearable: true,
            isSearchable: true,
          },
          {
            component: 'select',
            placeholder: 'Type',
            options: _.sortBy(usagetypes, ['label']),
            name: 'type',
            label: 'Type',
            value: '',
            isClearable: true,
            isSearchable: true,
          },
          {
            component: 'select',
            placeholder: 'Default value',
            options: _.sortBy(defaultValues?.map(itemToOptions), ['label']),
            name: 'default',
            label: 'Default',
            value: '',
            isClearable: true,
            isSearchable: true,
          },
        ],
        modalTitle: 'Add New Usage Type Detail',
        btn: {
          title: 'Add Usage Type Detail',
          style: { marginTop: 5, backgroundColor: primaryButton },
        },
        tableName: 'usageTypeDetails',
      });
    }

    case 'usageTypeDetails-table-edit': {
      return (modalProps = {
        elements: [
          {
            type: 'text',
            placeholder: 'Display name',
            name: 'displayName',
            label: 'Display Name',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Subentity',
            options: _.sortBy(subentities?.map(itemToOptions), ['label']),
            name: 'subentity',
            label: 'Subentity',
            value: null,
            isClearable: true,
            isSearchable: true,
          },
          {
            component: 'select',
            placeholder: 'Type',
            options: _.sortBy(usagetypes, ['label']),
            name: 'type',
            label: 'Type',
            value: '',
            isClearable: true,
            isSearchable: true,
          },
          {
            component: 'select',
            placeholder: 'Default value',
            options: item
              ? _.sortBy(usageTypeValues?.map(itemToOptions), ['label'])
              : _.sortBy(defaultValues?.map(itemToOptions), ['label']),
            name: 'default',
            label: 'Default',
            value: '',
            isClearable: true,
            isSearchable: true,
          },
        ],
        modalTitle: 'Edit Usage Type Detail',
        btn: {
          title: 'Edit Usage Type Detail',
          style: { marginTop: 5, backgroundColor: primaryButton },
        },
        tableName: 'usageTypeDetails',
      });
    }

    case 'usageTypeDetails-table-delete': {
      return (modalProps = {
        modalTitle: 'Are you sure you want to delete this Usage Type Detail',
        modalSubtitle: 'This action cannot be undone.',
        btn: {
          title: 'Delete Usage Type Detail',
          style: { marginTop: 20 },
          type: 'submit',
        },
        tableName: 'usageTypeDetails',
      });
    }

    case 'integrationMappings-table-add': {
      return (modalProps = {
        elements: [
          {
            component: 'select',
            placeholder: 'Entity',
            options: _.sortBy(entities?.map(itemToOptions), ['label']),
            name: 'entityId',
            label: 'Entity',
            value: '',
            isClearable: true,
            isSearchable: true,
          },
          {
            component: 'select',
            placeholder: 'Subentity',
            options: _.sortBy(subentities?.map(itemToOptions), ['label']),
            name: 'subentityId',
            label: 'Subentity',
            value: '',
            isClearable: true,
            isSearchable: true,
          },
          {
            component: 'select',
            placeholder: 'Production',
            options: _.sortBy(usageTypes['production']?.map(itemToOptions), ['label']),
            name: 'production',
            label: 'Production',
            value: '',
            isClearable: true,
            isSearchable: true,
          },
          {
            component: 'select',
            placeholder: 'Load',
            options: _.sortBy(usageTypes['load']?.map(itemToOptions), ['label']),
            name: 'load',
            label: 'Load',
            value: '',
            isClearable: true,
            isSearchable: true,
          },
          {
            component: 'select',
            placeholder: 'Seat',
            options: _.sortBy(usageTypes['seat']?.map(itemToOptions), ['label']),
            name: 'seat',
            label: 'Seat',
            value: '',
            isClearable: true,
            isSearchable: true,
          },
          {
            component: 'select',
            placeholder: 'Vehicle',
            options: _.sortBy(usageTypes['vehicle']?.map(itemToOptions), ['label']),
            name: 'vehicle',
            label: 'Vehicle',
            value: '',
            isClearable: true,
            isSearchable: true,
          },
          {
            component: 'select',
            placeholder: 'Fuel',
            options: _.sortBy(usageTypes['fuel']?.map(itemToOptions), ['label']),
            name: 'fuel',
            label: 'Fuel',
            value: '',
            isClearable: true,
            isSearchable: true,
          },
          {
            component: 'select',
            placeholder: 'Waste',
            options: _.sortBy(usageTypes['waste']?.map(itemToOptions), ['label']),
            name: 'waste',
            label: 'Waste',
            value: '',
            isClearable: true,
            isSearchable: true,
          },
          {
            component: 'select',
            placeholder: 'Price',
            options: _.sortBy(usageTypes['price']?.map(itemToOptions), ['label']),
            name: 'price',
            label: 'Price',
            value: '',
            isClearable: true,
            isSearchable: true,
          },
          {
            component: 'select',
            placeholder: 'Factor Provider',
            options: _.sortBy(usageTypes['factorprovider']?.map(itemToOptions), ['label']),
            name: 'factorprovider',
            label: 'Factor Provider',
            value: '',
            isClearable: true,
            isSearchable: true,
          },
          {
            component: 'select',
            placeholder: 'Origin Location',
            options: _.sortBy(usageTypes['originlocation']?.map(itemToOptions), ['label']),
            name: 'originlocation',
            label: 'Origin Location',
            value: '',
            isClearable: true,
            isSearchable: true,
          },
          {
            component: 'select',
            placeholder: 'Final Location',
            options: _.sortBy(usageTypes['finallocation']?.map(itemToOptions), ['label']),
            name: 'finallocation',
            label: 'Final Location',
            value: '',
            isClearable: true,
            isSearchable: true,
          },
        ],
        modalTitle: 'Add New Integration Mapping',
        btn: {
          title: 'Add New Integration Mapping',
          style: { marginTop: 5, backgroundColor: primaryButton },
        },
        tableName: 'integrationMappings',
      });
    }

    case 'integrationMappings-table-edit': {
      return (modalProps = {
        elements: [
          {
            component: 'select',
            placeholder: 'Entity',
            options: _.sortBy(entities?.map(itemToOptions), ['label']),
            name: 'entityId',
            label: 'Entity',
            value: '',
            isClearable: true,
            isSearchable: true,
          },
          {
            component: 'select',
            placeholder: 'Subentity',
            options: _.sortBy(subentities?.map(itemToOptions), ['label']),
            name: 'subentityId',
            label: 'Subentity',
            value: '',
            isClearable: true,
            isSearchable: true,
          },
          {
            component: 'select',
            placeholder: 'Production',
            options: _.sortBy(usageTypes['production']?.map(itemToOptions), ['label']),
            name: 'production',
            label: 'Production',
            value: '',
            isClearable: true,
            isSearchable: true,
          },
          {
            component: 'select',
            placeholder: 'Load',
            options: _.sortBy(usageTypes['load']?.map(itemToOptions), ['label']),
            name: 'load',
            label: 'Load',
            value: '',
            isClearable: true,
            isSearchable: true,
          },
          {
            component: 'select',
            placeholder: 'Seat',
            options: _.sortBy(usageTypes['seat']?.map(itemToOptions), ['label']),
            name: 'seat',
            label: 'Seat',
            value: '',
            isClearable: true,
            isSearchable: true,
          },
          {
            component: 'select',
            placeholder: 'Vehicle',
            options: _.sortBy(usageTypes['vehicle']?.map(itemToOptions), ['label']),
            name: 'vehicle',
            label: 'Vehicle',
            value: '',
            isClearable: true,
            isSearchable: true,
          },
          {
            component: 'select',
            placeholder: 'Fuel',
            options: _.sortBy(usageTypes['fuel']?.map(itemToOptions), ['label']),
            name: 'fuel',
            label: 'Fuel',
            value: '',
            isClearable: true,
            isSearchable: true,
          },
          {
            component: 'select',
            placeholder: 'Waste',
            options: _.sortBy(usageTypes['waste']?.map(itemToOptions), ['label']),
            name: 'waste',
            label: 'Waste',
            value: '',
            isClearable: true,
            isSearchable: true,
          },
          {
            component: 'select',
            placeholder: 'Price',
            options: _.sortBy(usageTypes['price']?.map(itemToOptions), ['label']),
            name: 'price',
            label: 'Price',
            value: '',
            isClearable: true,
            isSearchable: true,
          },
          {
            component: 'select',
            placeholder: 'Factor Provider',
            options: _.sortBy(usageTypes['factorprovider']?.map(itemToOptions), ['label']),
            name: 'factorprovider',
            label: 'Factor Provider',
            value: '',
            isClearable: true,
            isSearchable: true,
          },
          {
            component: 'select',
            placeholder: 'Origin Location',
            options: _.sortBy(usageTypes['originlocation']?.map(itemToOptions), ['label']),
            name: 'originlocation',
            label: 'Origin Location',
            value: '',
            isClearable: true,
            isSearchable: true,
          },
          {
            component: 'select',
            placeholder: 'Final Location',
            options: _.sortBy(usageTypes['finallocation']?.map(itemToOptions), ['label']),
            name: 'finallocation',
            label: 'Final Location',
            value: '',
            isClearable: true,
            isSearchable: true,
          },
        ],
        modalTitle: 'Edit Integration Mapping',
        btn: {
          title: 'Edit Integration Mapping',
          style: { marginTop: 5, backgroundColor: primaryButton },
        },
        tableName: 'integrationMappings',
      });
    }

    case 'integrationMappings-table-delete': {
      return (modalProps = {
        modalTitle: 'Are you sure you want to delete this Integration Mapping',
        modalSubtitle: 'This action cannot be undone.',
        btn: {
          title: 'Delete Integration Mapping',
          style: { marginTop: 20 },
          type: 'submit',
        },
        tableName: 'integrationMappings',
      });
    }

    case 'currencies-table-add': {
      return (modalProps = {
        elements: [
          {
            type: 'text',
            placeholder: 'Name',
            name: 'name',
            label: 'Name',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'ISO Code',
            name: 'isoCode',
            label: 'ISO Code',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Symbol',
            name: 'symbol',
            label: 'Symbol',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Font: Arial Unicode',
            name: 'font:ArialUnicode',
            label: 'Font: Arial Unicode',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Unicode: Decimal',
            name: 'unicode:Decimal',
            label: 'Unicode: Decimal',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Unicode: Hex',
            name: 'unicode:Hex',
            label: 'Unicode: Hex',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Hide in UI',
            name: 'hideInUI',
            label: 'Hide in UI',
            options: [
              { label: 'True', value: true },
              { label: 'False', value: false },
            ],
            value: '',
          },
        ],
        modalTitle: 'Add a Currency',
        btn: {
          title: 'Add Currency',
          style: { marginTop: 20, backgroundColor: primaryButton, border: 'none', color: textColor },
        },
        tableName: 'currencies',
      });
    }

    case 'currencies-table-edit': {
      return (modalProps = {
        elements: [
          {
            type: 'text',
            placeholder: 'Name',
            name: 'name',
            label: 'Name',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'ISO Code',
            name: 'isoCode',
            label: 'ISO Code',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Symbol',
            name: 'symbol',
            label: 'Symbol',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Font: Arial Unicode',
            name: 'font:ArialUnicode',
            label: 'Font: Arial Unicode',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Unicode: Decimal',
            name: 'unicode:Decimal',
            label: 'Unicode: Decimal',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Unicode: Hex',
            name: 'unicode:Hex',
            label: 'Unicode: Hex',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Hide in UI',
            name: 'hideInUI',
            label: 'Hide in UI',
            options: [
              { label: 'True', value: true },
              { label: 'False', value: false },
            ],
            value: '',
          },
        ],
        modalTitle: 'Edit a Currency',
        btn: {
          title: 'Edit Currency',
          style: { marginTop: 20, backgroundColor: primaryButton, border: 'none', color: textColor },
        },
        tableName: 'currencies',
      });
    }

    case 'currencies-table-delete': {
      // eslint-disable-next-line
      return (modalProps = {
        modalTitle: 'Are you sure you want to delete this Currency?',
        modalSubtitle: 'This Action cannot be undone.',
        btn: {
          title: 'Delete Currency',
          style: { marginTop: 20 },
          type: 'submit',
        },
        tableName: 'currencies',
      });
    }

    case 'features-table-add': {
      return (modalProps = {
        elements: [
          {
            type: 'text',
            placeholder: 'Name',
            name: 'name',
            label: 'Name',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Feature Key',
            name: 'featureKey',
            label: 'Feature Key (Once created can not be changed)',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Description',
            name: 'description',
            label: 'Description',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Enabled By Default',
            name: 'enabledByDefault',
            label: 'Enabled By Default',
            options: [
              { label: 'True', value: true },
              { label: 'False', value: false },
            ],
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Is Beta',
            name: 'isBeta',
            label: 'Is Beta',
            options: [
              { label: 'True', value: true },
              { label: 'False', value: false },
            ],
            value: '',
          },
        ],
        modalTitle: 'Add a Feature',
        btn: {
          title: 'Add Feature',
          style: { marginTop: 20, backgroundColor: primaryButton, border: 'none', color: textColor },
        },
        tableName: 'features',
      });
    }

    case 'features-table-edit': {
      return (modalProps = {
        elements: [
          {
            type: 'text',
            placeholder: 'Name',
            name: 'name',
            label: 'Name',
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Feature Key',
            name: 'featureKey',
            label: 'Feature Key',
            disabled: true,
            value: '',
          },
          {
            type: 'text',
            placeholder: 'Description',
            name: 'description',
            label: 'Description',
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Enabled By Default',
            name: 'enabledByDefault',
            label: 'Enabled By Default',
            options: [
              { label: 'True', value: true },
              { label: 'False', value: false },
            ],
            value: '',
          },
          {
            component: 'select',
            placeholder: 'Is Beta',
            name: 'isBeta',
            label: 'Is Beta',
            options: [
              { label: 'True', value: true },
              { label: 'False', value: false },
            ],
            value: '',
          },
        ],
        modalTitle: 'Edit a Feature',
        btn: {
          title: 'Edit Feature',
          style: { marginTop: 20, backgroundColor: primaryButton, border: 'none', color: textColor },
        },
        tableName: 'features',
      });
    }

    case 'linkStripeAccount': {
      return (modalProps = {
        modalTitle: 'Link Organisation to Stripe Account',
        elements: [
          {
            component: 'tab',
            labels: ['Link Account', 'Unlink Account'],
            value: '0',
          },
          {
            type: 'text',
            placeholder: 'Customer ID',
            name: 'stripeAccountId',
            label: 'Stripe Customer ID',
            value: '',
          },
          {
            //admin Organisations select
            component: 'select',
            placeholder: 'Organisation',
            options: _.sortBy(adminOrgs?.map(itemToOptions), ['label']),
            isSearchable: true,
            name: 'organisationId',
            label: 'Organisation',
            value: '',
          },
          {
            type: 'warning',
            title: 'Warning',
            label:
              "Please note that this will overwrite the organisation's current subscription end date with the one stored on Stripe.",
          },
        ],
        btn: {
          title: 'Link Stripe Account',
          style: { marginTop: 20 },
          type: 'submit',
        },
      });
    }

    case 'unlinkStripeAccount': {
      return (modalProps = {
        modalTitle: 'Unlink Organisation from Stripe Account',
        elements: [
          {
            component: 'tab',
            labels: ['Link Account', 'Unlink Account'],
            value: '1',
          },
          {
            //admin Organisations select
            component: 'select',
            placeholder: 'Organisation',
            options: _.sortBy(adminOrgs?.map(itemToOptions), ['label']),
            isSearchable: true,
            name: 'organisationID',
            label: 'Organisation',
            value: '',
          },
          {
            component: 'datePicker',
            placeholder: 'Subscription End Date',
            name: 'subscriptionEndDate',
            label: 'New Subscription End Date',
            value: '',
            styles: { width: 260 },
          },
          {
            component: 'StripeCustomerId',
            placeholder: 'Customer ID',
            name: 'stripeAccountId',
            label: 'Stripe Customer ID',
            value: '',
          },
        ],
        btn: {
          title: 'Unlink Stripe Account',
          style: { marginTop: 20 },
          type: 'submit',
        },
      });
    }

    default:
      break;
  }
};
